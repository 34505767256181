import styled from '@emotion/styled/macro';

export const NPSContainer = styled.div`
  .flex {
    display: flex;
  }

  .custom-legend {
    .legend {
      align-items: center;
    }
  }

  .stack-column-chart {
    height: 400px;
  }
`;
