import api from './api';

export const getCsiCornerApi = (filter: any) => {
  const {
    user_type,
    languge,
    year,
    halfyear,
    quarter,
    month,
    period,
    region,
    dealer,
    branch,
    buble_cor_sub,
    buble_cor_cei,
    buble_cor_nps,
    buble_cor_improve,
  } = filter;

  let link = '/v1/corner?';

  if (user_type) {
    link = link + `user_type=${user_type}&`;
  }

  if (languge) {
    link = link + `languge=${languge}&`;
  }

  if (year) {
    link = link + `year=${year}&`;
  }

  if (halfyear) {
    link = link + `halfyear=${halfyear}&`;
  }

  if (quarter) {
    link = link + `quarter=${quarter}&`;
  }

  if (month) {
    link = link + `month=${month}&`;
  }

  if (period) {
    link = link + `period=${period}&`;
  }

  if (region) {
    link = link + `region=${region}&`;
  }

  if (dealer) {
    link = link + `dealer=${dealer}&`;
  }

  if (branch) {
    link = link + `branch=${branch}&`;
  }

  if (link.charAt(link.length - 1) === '?' || link.charAt(link.length - 1) === '&') {
    link = link.slice(0, link.length - 1);
  }

  return api.get(link, { params: { buble_cor_sub, buble_cor_cei, buble_cor_nps, buble_cor_improve } });
};
