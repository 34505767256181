import {
  dealerSelected,
  halfYearSelected,
  languageSelected,
  monthSelected,
  periodSelected,
  quarterSelected,
  retrieveFilter,
  yearSelected,
} from 'store/filterSlice';
import { useEffect, useMemo } from 'react';

import { NavContainer } from './style';
import { Select } from 'antd';
import { useAppDispatch } from 'store';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const { Option } = Select;

const Nav = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const users = useSelector((state: any) => state.auth.users);
  const filter = useSelector((state: any) => state.filter);

  const renderTitle = useMemo(() => {
    let titleName = location.pathname.slice(1).replace('-', ' ').toUpperCase();

    if (['/summary'].includes(location.pathname)) {
      titleName = 'ข้อมูลสรุป';
    }

    if (['/csi-lexus-dealers'].includes(location.pathname)) {
      titleName = 'CSI - Lexus Dealers';
    }

    if (['/csi-service-corner'].includes(location.pathname)) {
      titleName = 'CSI - Service Corner';
    }

    return (
      <div className="title">
        <h1 style={{ textTransform: 'capitalize' }}>{titleName}</h1>
      </div>
    );
  }, [location.pathname]);

  const filterLanguage = useMemo(() => {
    return (
      <Select value={filter.languageSelected} onChange={(value) => dispatch(languageSelected(value))}>
        {filter.language.map?.((data: any) => (
          <Option key={data.id} value={data.id}>
            {data.name}
          </Option>
        ))}
      </Select>
    );
  }, [filter.languageSelected, filter.language]);

  const filterYear = useMemo(() => {
    return (
      <Select value={filter.yearSelected} onChange={(value) => dispatch(yearSelected(value))}>
        {filter.year.map?.((data: any) => (
          <Option key={data.id} value={data.id}>
            {data.name}
          </Option>
        ))}
      </Select>
    );
  }, [filter.yearSelected, filter.year]);

  const filterHalfYear = useMemo(() => {
    return (
      <Select value={filter.halfYearSelected} onChange={(value) => dispatch(halfYearSelected(value))}>
        {filter.halfYear.map?.((data: any) => (
          <Option key={data.id} value={data.id}>
            {data.name}
          </Option>
        ))}
      </Select>
    );
  }, [filter.halfYearSelected, filter.halfYear]);

  const filterMonth = useMemo(() => {
    return (
      <Select value={filter.monthSelected} onChange={(value) => dispatch(monthSelected(value))}>
        {filter.month.map?.((data: any) => (
          <Option key={data.id} value={data.id}>
            {data.name}
          </Option>
        ))}
      </Select>
    );
  }, [filter.monthSelected, filter.month]);

  const filterQuarter = useMemo(() => {
    return (
      <Select value={filter.quarterSelected} onChange={(value) => dispatch(quarterSelected(value))}>
        {filter.quarter.map?.((data: any) => (
          <Option key={data.id} value={data.id}>
            {data.name}
          </Option>
        ))}
      </Select>
    );
  }, [filter.quarterSelected, filter.quarter]);

  const filterDealer = useMemo(() => {
    return (
      <Select value={filter.dealerSelected} onChange={(value) => dispatch(dealerSelected(value))}>
        {filter.dealer.map?.((data: any) => (
          <Option key={data.id} value={data.id}>
            {data.name}
          </Option>
        ))}
      </Select>
    );
  }, [filter.dealerSelected, filter.dealer]);

  const filterPeriod = useMemo(() => {
    return (
      <Select value={filter.periodSelected} onChange={(value) => dispatch(periodSelected(value))}>
        {filter.period.map?.((data: any) => (
          <Option key={data.id} value={data.id}>
            {data.name}
          </Option>
        ))}
      </Select>
    );
  }, [filter.periodSelected, filter.period]);

  useEffect(() => {
    dispatch(dealerSelected(users?.dealer_id));
  }, []);

  useEffect(() => {
    dispatch(
      retrieveFilter({
        user_type: users?.user_type,
        language: 'TH',
        dealer_id: users?.dealer_id,
        year: filter.yearSelected,
        month: filter.monthSelected,
      }),
    );
  }, [filter.yearSelected, filter.monthSelected]);

  return (
    <NavContainer>
      {renderTitle}
      <div className="filter-group">
        <div>
          <div className="filter-label">ภาษา</div>
          {filterLanguage}
        </div>
        <div>
          <div className="filter-label">ปี</div>
          {filterYear}
        </div>
        <div>
          <div className="filter-label">ครึ่งปี</div>
          {filterHalfYear}
        </div>
        <div>
          <div className="filter-label">เดือน</div>
          {filterMonth}
        </div>
        <div>
          <div className="filter-label">ไตรมาส</div>
          {filterQuarter}
        </div>
        <div style={{ width: 200 }}>
          <div className="filter-label">ดีลเลอร์</div>
          {filterDealer}
        </div>
        <div style={{ width: 200 }}>
          <div className="filter-label">ช่วงเวลา</div>
          {filterPeriod}
        </div>
      </div>
      <hr />
    </NavContainer>
  );
};

export default Nav;
