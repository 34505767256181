import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getCsiApi } from 'services/csi';
import { logout } from './authSlice';

export const getCsi = createAsyncThunk('csi/getCsi', async (filter: any, thunk: any) => {
  try {
    const response = await getCsiApi(filter);

    return response.data;
  } catch (error: any) {
    if (error.response.status === 401) {
      thunk.dispatch(logout());
    }
  }
});

interface CsiState {
  data: any;
  error: boolean;
  loading: boolean;
}

const initialState: CsiState = {
  data: {},
  error: false,
  loading: false,
};

export const csiSlice = createSlice({
  name: 'csi',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCsi.fulfilled, (state, action: PayloadAction<any>) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(getCsi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCsi.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default csiSlice.reducer;
