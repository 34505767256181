import { IndicatorsContainer } from './style';

type IndicatorsType = {
  headers?: any[];
  data: { target_text: string; target_text_color: string; ssi?: string; csi?: string; cei?: string; nps?: string }[];
  [restProps: string]: any;
};

const Indicators = ({ headers, data, ...restProps }: IndicatorsType) => {
  return (
    <IndicatorsContainer {...restProps}>
      <table>
        <thead>
          <tr>
            <th style={{ backgroundColor: headers?.[0] && '#ccc' }}>{headers?.[0]}</th>
            {(data?.[0]?.ssi || data?.[0]?.csi) && (
              <th style={{ backgroundColor: 'rgba(29, 121, 255, 1)' }}>{headers?.[1] ?? 'SSI Score'}</th>
            )}
            {data?.[0]?.cei && (
              <th style={{ backgroundColor: 'rgba(0, 161, 162, 1)' }}>{headers?.[2] ?? 'CEI Score'}</th>
            )}
            {data?.[0]?.nps && <th style={{ backgroundColor: 'rgba(240, 100, 0, 1)' }}>{headers?.[3] ?? 'NPS'}</th>}
          </tr>
        </thead>
        <tbody>
          {data?.map?.((row, index) => (
            <tr key={index}>
              <td
                style={{
                  backgroundColor: row?.target_text_color ?? 'rgba(29, 121, 255, 0.3)',
                  textDecoration: 'underline',
                }}
              >
                {row?.target_text}
              </td>
              {(row?.ssi || row?.csi) && (
                <td style={{ backgroundColor: 'rgba(29, 121, 255, 0.3)' }}>{row?.ssi || row?.csi}</td>
              )}
              {row?.cei && <td style={{ backgroundColor: 'rgba(0, 161, 162, 0.3)' }}>{row?.cei}</td>}
              {row?.nps && <td style={{ backgroundColor: 'rgba(240, 100, 0, 0.3)' }}>{row?.nps}</td>}
            </tr>
          ))}
        </tbody>
      </table>
    </IndicatorsContainer>
  );
};

export default Indicators;
