import api from './api';

export const retrieveFilterApi = (filter: any) => {
  const { user_type, language, dealer_id, year, month } = filter;

  let link = '/v1/filter?';

  if (user_type) {
    link = link + `user_type=${user_type}&`;
  }

  if (language) {
    link = link + `languge=${language}&`;
  }

  if (dealer_id) {
    link = link + `dealer=${dealer_id}&`;
  }

  if (year) {
    link = link + `year=${year}&`;
  }

  if (month) {
    link = link + `month=${month}&`;
  }

  if (link.charAt(link.length - 1) === '?' || link.charAt(link.length - 1) === '&') {
    link = link.slice(0, link.length - 1);
  }

  return api.get(link);
};
