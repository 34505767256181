import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'store';
import { getSummary } from 'store/summarySlice';
import SalesPerformance from './salesPerformance';
import After1 from './after1';
import After2 from './after2';

const Summary = () => {
  const dispatch = useAppDispatch();
  const filter = useAppSelector((state) => state.filter);
  const users = useSelector((state: any) => state.auth.users);
  const data = useSelector((state: any) => state.summary.data);

  useEffect(() => {
    if (filter.fetchFilterState && users.user_type) {
      dispatch(
        getSummary({
          user_type: users.user_type,
          languge: filter.languageSelected,
          year: filter.yearSelected,
          halfyear: filter.halfYearSelected,
          quarter: filter.quarterSelected,
          month: filter.monthSelected,
          period: filter.periodSelected,
          region: users.region_id ?? '',
          dealer: filter.dealerSelected,
          branch: users.branch_id ?? '',
          buble_ssi: filter?.bubbleSsiSelected,
          buble_csi: filter?.bubbleCsiSelected,
          buble_cor: filter?.bubbleCorSelected,
        }),
      );
    }
  }, [filter]);

  return (
    <>
      <SalesPerformance data={data} />
      <After1 data={data} />
      <After2 data={data} />
    </>
  );
};

export default Summary;
