import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});

api.interceptors.request.use(
  function (config) {
    const users = JSON.parse(localStorage.getItem('users') ?? '{}');

    config.headers.Authorization = `Bearer ${users?.authToken ?? ''}`;

    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

export default api;
