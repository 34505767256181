import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getSsiApi } from 'services/ssi';
import { logout } from './authSlice';

export const getSsi = createAsyncThunk('ssi/getSsi', async (filter: any, thunk: any) => {
  try {
    const response = await getSsiApi(filter);

    return response.data;
  } catch (error: any) {
    if (error.response.status === 401) {
      thunk.dispatch(logout());
    }
  }
});

interface SsiState {
  data: any;
  error: boolean;
  loading: boolean;
}

const initialState: SsiState = {
  data: {},
  error: false,
  loading: false,
};

export const ssiSlice = createSlice({
  name: 'ssi',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSsi.fulfilled, (state, action: PayloadAction<any>) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(getSsi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSsi.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default ssiSlice.reducer;
