import { BarChartContainer } from './style';

type BarChartType = { colors: any[]; data: any[] };

const BarChart = ({ colors, data }: BarChartType) => {
  return (
    <BarChartContainer>
      <div className="wrapper-box">
        {data?.map?.((bar: any, index: number) => {
          const total = bar?.total;

          return (
            <div className="row-in-box" key={bar?.name}>
              <div className="label-yaxis">{bar?.name}</div>
              <div className="wrapper-bar-out">
                <div className="bar-out">
                  <div
                    className="target-line"
                    style={{ borderColor: colors[index], left: `${(bar?.target / total) * 100}%` }}
                  >
                    <div className="target-name" style={{ color: colors[index] }}>
                      {`${bar?.target_text} ${bar?.target}`}
                    </div>
                  </div>
                  <div className="bar-out-point">{total == 100 ? `${total}%` : `${total} PTs`}</div>
                  <div
                    className="bar-in"
                    style={{ backgroundColor: colors[index], width: `${(bar?.score / total) * 100}%` }}
                  >
                    <div className="bar-in-point">{total == 100 ? `${bar?.score}%` : `${bar?.score} PTs`}</div>
                  </div>
                  <div className="bar-in" style={{ width: `${(bar?.score_2020 / total) * 100}%` }}></div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </BarChartContainer>
  );
};

export default BarChart;
