import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Login from 'pages/login';
import Summary from 'pages/summary';
import SSI from 'pages/ssi';
import CSILexusDealers from 'pages/csiLexusDealers';
import CSIServiceCorner from 'pages/csiServiceCorner';
import MainLayout from '../layout';
import { useSelector } from 'react-redux';

const Router = () => {
  const auth = useSelector((state: any) => state.auth);

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <Login />
        </Route>
        <Route>
          {auth.auth ? (
            <MainLayout>
              <Switch>
                <Route path="/summary">
                  <Summary />
                </Route>
                <Route path="/ssi">
                  <SSI />
                </Route>
                <Route path="/csi-lexus-dealers">
                  <CSILexusDealers />
                </Route>
                <Route path="/csi-service-corner">
                  <CSIServiceCorner />
                </Route>
              </Switch>
            </MainLayout>
          ) : (
            <Redirect to="/" />
          )}
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
