import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import authReducer from './authSlice';
import csiReducer from './csiSlice';
import csiCornerReducer from './csiCornerSlice';
import filterReducer from './filterSlice';
import ssiReducer from './ssiSlice';
import summaryReducer from './summarySlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    csi: csiReducer,
    csiCorner: csiCornerReducer,
    filter: filterReducer,
    ssi: ssiReducer,
    summary: summaryReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const dispatch = store.dispatch;
export const state = store.getState();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
