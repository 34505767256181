import styled from '@emotion/styled';

export const NavContainer = styled.div`
  padding: 20px 20px 0px 20px;
  background-color: #f2f2f3;

  .ant-select {
    width: 100%;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: rgba(228, 231, 231, 1);
    border: none;
    border-radius: 7px;
    font-weight: 600;
    font-size: 0.9rem;
  }

  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border-color: none !important;
    box-shadow: none !important;
  }

  .title {
    display: flex;
    align-items: center;
    margin: 10px 0 15px 0;

    .title-icon {
      background-color: rgba(var(--app-code), 0.2);
      color: var(--app-color);
      width: 36px;
      height: 36px;
      border-radius: 50%;
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    h1 {
      font-size: 2rem;
      font-weight: 500;
    }
  }

  .filter-group {
    display: flex;
    width: 100%;

    > div {
      min-width: 100px;
    }

    > div:not(:last-child) {
      margin-right: 10px;
    }

    .filter-label {
      display: block;
      color: var(--secondary-color);
      font-weight: 600;
      font-size: 0.92rem;
      margin-bottom: 5px;
    }
  }

  hr {
    margin-top: 20px;
    margin-bottom: 0px;
    border-color: rgba(228, 231, 231, 0.3);
  }
`;
