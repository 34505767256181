import styled from '@emotion/styled/macro';

export const MeanContainer = styled.div`
  width: 100%;

  .mean-title {
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .row-color {
    padding: 10px 15px;
    border-radius: 5px;
    text-align: center;

    span {
      font-weight: 500;
    }
  }

  .row-color:not(:last-child) {
    margin-bottom: 15px;
  }
`;
