import { Bar, BarChart, CartesianGrid, LabelList, Legend, Tooltip, XAxis, YAxis } from 'recharts';
import { useEffect, useState } from 'react';

import { Container } from './style';

const StackBarChart = ({ data }: any) => {
  const [random, setRandom] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setRandom(Math.random());
    }, 500);
  }, [data]);

  return (
    <Container key={random}>
      <BarChart
        width={500}
        height={300}
        data={data}
        layout="vertical"
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" tickFormatter={(tick) => tick + '%'} domain={[0, 100]} />
        <YAxis dataKey="name" type="category" />
        <Tooltip />
        <Legend verticalAlign="top" align="center" width={500} height={50} />
        <Bar dataKey="10" stackId="a" fill="#3fad55">
          <LabelList
            dataKey="10"
            formatter={(e) => {
              if (e === 0) return '';
              return e + '%';
            }}
          />
        </Bar>
        <Bar dataKey="9" stackId="a" fill="#64be7a">
          <LabelList
            dataKey="9"
            formatter={(e) => {
              if (e === 0) return '';
              return e + '%';
            }}
          />
        </Bar>
        <Bar dataKey="8" stackId="a" fill="#b1d47f">
          <LabelList
            dataKey="8"
            formatter={(e) => {
              if (e === 0) return '';
              return e + '%';
            }}
          />
        </Bar>
        <Bar dataKey="7" stackId="a" fill="#ccdc81">
          <LabelList
            dataKey="7"
            formatter={(e) => {
              if (e === 0) return '';
              return e + '%';
            }}
          />
        </Bar>
        <Bar dataKey="6" stackId="a" fill="#fce984">
          <LabelList
            dataKey="6"
            formatter={(e) => {
              if (e === 0) return '';
              return e + '%';
            }}
          />
        </Bar>
        <Bar dataKey="5" stackId="a" fill="#f9c97e">
          <LabelList
            dataKey="5"
            formatter={(e) => {
              if (e === 0) return '';
              return e + '%';
            }}
          />
        </Bar>
        <Bar dataKey="4" stackId="a" fill="#f6a677">
          <LabelList
            dataKey="4"
            formatter={(e) => {
              if (e === 0) return '';
              return e + '%';
            }}
          />
        </Bar>
        <Bar dataKey="3" stackId="a" fill="#f58986">
          <LabelList
            dataKey="3"
            formatter={(e) => {
              if (e === 0) return '';
              return e + '%';
            }}
          />
        </Bar>
        <Bar dataKey="2" stackId="a" fill="#f35d5e">
          <LabelList
            dataKey="2"
            formatter={(e) => {
              if (e === 0) return '';
              return e + '%';
            }}
          />
        </Bar>
        <Bar dataKey="1" stackId="a" fill="#f10007">
          <LabelList
            dataKey="1"
            formatter={(e) => {
              if (e === 0) return '';
              return e + '%';
            }}
          />
        </Bar>
      </BarChart>
    </Container>
  );
};

export default StackBarChart;
