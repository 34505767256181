import styled from '@emotion/styled/macro';

export const BoxBorderContainer = styled.div`
  position: relative;
  border: 1px solid;
  border-radius: 6px;
  padding: 20px;

  .title-border {
    position: absolute;
    top: -15px;
    left: 12px;
    background-color: #fff;
    padding: 0 10px;
    font-size: 1.2rem;
    font-weight: 500;
    height: 30px;
    display: flex;
    align-items: center;
  }
`;
