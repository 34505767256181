import { useEffect } from 'react';
import { Form, Input, Button, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { LoginContainer } from './style';
import { login, retrieveUser } from 'store/authSlice';
import { useAppDispatch } from 'store';
import { useSelector } from 'react-redux';

const Login = () => {
  const dispatch = useAppDispatch();
  const auth = useSelector((state: any) => state.auth);
  const history = useHistory();

  const onFinish = (values: any) => {
    dispatch(login({ username: values.username, password: values.password }));
  };

  useEffect(() => {
    if (auth.auth) {
      dispatch(retrieveUser({})).then(() => history.push('/summary'));
    }
  }, [auth.auth]);

  useEffect(() => {
    if (auth.error) {
      message.error('ชื่อผู้ใช้ หรือรหัสผ่านไม่ถูกต้อง');
    }
  }, [auth.error]);

  return (
    <>
      {auth.auth === false && (
        <LoginContainer>
          <div className="left">
            <img src="/images/logo.png" alt="logo" />
          </div>
          <div className="right">
            <div className="login-box">
              <Form
                name="normal_login"
                className="login-form"
                layout="vertical"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                requiredMark={false}
              >
                <div className="header-login-box">เข้าสู่ระบบ</div>
                <Form.Item
                  label="ชื่อผู้ใช้"
                  name="username"
                  rules={[{ required: true, message: 'กรุณากรอกชื่อผู้ใช้' }]}
                >
                  <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                </Form.Item>
                <Form.Item label="รหัสผ่าน" name="password" rules={[{ required: true, message: 'กรุณากรอกรหัสผ่าน' }]}>
                  <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                  />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" className="login-form-button">
                    เข้าสู่ระบบ
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </LoginContainer>
      )}
    </>
  );
};

export default Login;
