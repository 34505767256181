import * as Common from 'components/common';

import { Select, Table } from 'antd';
import { useAppDispatch, useAppSelector } from 'store';
import { useEffect, useState } from 'react';

import BasicColumnChart from 'components/basicColumnChart';
import BubbleChart from 'components/bubbleChart';
import CompareTable from 'components/compareTable';
import DonutChart from 'components/donutChart';
import Indicators from 'components/indicators';
import Legends from 'components/legends';
import LineChart from 'components/lineChart';
import MixedChart from 'components/mixedChart';
import Note from 'components/note';
import { SSIScoreContainer } from './style';
import StackBarChart from 'components/stackBarChart';
import TableWithLineChart from 'components/tableWithLineChart';
import { renderColors } from 'utils/renderColors';
import { ssiBubbleS1Selected } from 'store/filterSlice';

const SSIScore = ({ data }: any) => {
  const [trendsHeader, setTrendsHeader] = useState('');
  const [trendsLabel, setTrendsLabel] = useState([]);
  const [trendsNationwide, setTrendsNationwide] = useState<number[]>([]);
  const [trendsDealer, setTrendsDealer] = useState<number[]>([]);
  const [trendsTarget, setTrendsTarget] = useState(0);
  const [trendsTargetText, setTrendsTargetText] = useState('');
  const [trendsLegend, setTrendsLegend] = useState<any>([]);
  const [trendsNotes, setTrendsNotes] = useState<string[]>([]);
  const [trendsShow, setTrendsShow] = useState(false);
  const [replyText, setReplyText] = useState('');
  const [replyColumn, setReplyColumn] = useState([]);
  const [replyData, setReplyData] = useState([]);
  const [factorText, setFactorText] = useState('');
  const [factorTarget, setFactorTarget] = useState(0);
  const [factorLegend, setFactorLegend] = useState<any>([]);
  const [factorShowroom, setFactorShowroom] = useState<number[]>([]);
  const [factorSales, setFactorSales] = useState<number[]>([]);
  const [factorDelivery, setFactorDelivery] = useState<number[]>([]);
  const [factorAfterSale, setFactorAfterSale] = useState<number[]>([]);
  const [factorSalesInitiation, setFactorSalesInitiation] = useState<number[]>([]);
  const [factorDealerFacility, setFactorDealerFacility] = useState<number[]>([]);
  const [factorDeal, setFactorDeal] = useState<number[]>([]);
  const [factorDeliveryTiming, setFactorDeliveryTiming] = useState<number[]>([]);
  const [factorCategories, setFactorCategories] = useState<any>([]);
  const [factorNotes, setFactorNotes] = useState<string[]>([]);
  const [factorShow, setFactorShow] = useState(false);
  const [factorSaleText, setFactorSaleText] = useState('');
  const [factorSaleLegend, setFactorSaleLegend] = useState<any>([]);
  const [factorSaleRow, setFactorSaleRow] = useState<any>([]);
  const [factorSaleRowData, setFactorSaleRowData] = useState<any>([]);
  const [factorSaleTarget, setFactorSaleTarget] = useState(0);
  const [factorSaleNotes, setFactorSaleNotes] = useState<string[]>([]);
  const [factorSaleShow, setFactorSaleShow] = useState(false);
  const [attributeText, setAttributeText] = useState('');
  const [attributeLabels, setAttributeLabels] = useState<string[]>([]);
  const [attributeStrong, setAttributeStrong] = useState([]);
  const [attributeImprove, setAttributeImprove] = useState([]);
  const [attributeNotes, setAttributeNotes] = useState<string[]>([]);
  const [attributeShow, setAttributeShow] = useState(false);
  const [satisfactionText, setSatisfactionText] = useState('');
  const [satisfactionScore, setSatisfactionScore] = useState<any>({});
  const [satisfactionColors, setSatisfactionColors] = useState<any>([]);
  const [satisfactionShow, setSatisfactionShow] = useState(false);
  const [purposeText, setPurposeText] = useState('');
  const [purposeLegend, setPurposeLegend] = useState<any>([]);
  const [purposeNotes, setPurposeNotes] = useState<any>([]);
  const [purposeCategories, setPurposeCategories] = useState<string[]>([]);
  const [purposeScore, setPurposeScore] = useState<any>([]);
  const [problemText, setProblemText] = useState('');
  const [problemLegend, setProblemLegend] = useState<any>([]);
  const [problemScore, setProblemScore] = useState<any>([]);
  const [problemCategories, setProblemCategories] = useState<any>([]);
  const [problemNotes, setProblemNotes] = useState<any>([]);
  const [problemShow, setProblemShow] = useState(false);
  const [Q30Data, setQ30Data] = useState<any>([]);
  const [Q31Data, setQ31Data] = useState<any>([]);
  const [Q30Note, setQ30Note] = useState<any>([]);
  const [Q30Top2Boxes, setQ30Top2Boxes] = useState<any>([]);
  const [Q31Note, setQ31Note] = useState<any>([]);
  const [Q31Top2Boxes, setQ31Top2Boxes] = useState<any>([]);
  const [Q30Topic, setQ30Topic] = useState('');
  const [Q31Topic, setQ31Topic] = useState('');
  const dispatch = useAppDispatch();
  const filter = useAppSelector((state) => state.filter);

  useEffect(() => {
    const ssiScore = data?.ssi_score_1_7 ?? undefined;
    const ssiScore2 = data?.ssi_score_2_7 ?? undefined;
    const ssiScore3 = data?.ssi_score_3_7 ?? undefined;
    const ssiScore4 = data?.ssi_score_4_7 ?? undefined;
    const ssiScore5 = data?.ssi_score_5_7 ?? undefined;
    const ssiScore6 = data?.ssi_score_6_7 ?? undefined;

    // Trends
    const nationwide: number[] = [];
    const dealer: number[] = [];
    let target = 0;
    const labels =
      ssiScore?.scores?.map?.((score: any) => {
        nationwide.push(score?.nationwide_score ?? 0);
        dealer.push(score?.dealer_score ?? 0);
        target = score?.target_score ?? 0;

        return score?.name ?? '';
      }) ?? [];
    setTrendsHeader(ssiScore?.header_text ?? '');
    setTrendsLabel(labels);
    setTrendsNationwide(nationwide);
    setTrendsDealer(dealer);
    setTrendsTarget(target);
    setTrendsTargetText(
      ssiScore?.legend?.find?.((legend: any) => {
        const regex = new RegExp('target', 'i');
        return legend.name.match(regex);
      })?.name ?? '',
    );
    setTrendsLegend(ssiScore?.legend ?? []);
    setTrendsNotes([ssiScore?.base_text ?? '', ssiScore?.ref_text ?? '']);
    setTrendsShow(ssiScore?.show ?? false);

    // Reply
    const replyTableName = ssiScore?.table_n_text ?? '';
    const replyColumn: any = [
      {
        title: replyTableName || 'จำนวนตอบกลับ',
        dataIndex: 'name',
        key: 'name',
        width: 173,
      },
    ];
    const replyDataMapKey: any = {
      nation: { key: '1', name: ssiScore?.legend[0]?.name },
      dealer: { key: '2', name: ssiScore?.legend[1]?.name },
    };

    for (const [index, score] of ssiScore?.table_n?.entries() ?? []) {
      const idx = `reply-${index}`;

      replyColumn.push({
        title: score?.name ?? '',
        dataIndex: idx,
        key: idx,
        align: 'center',
      });
      replyDataMapKey.nation[idx] = score?.nationwide_score ?? 0;
      replyDataMapKey.dealer[idx] = score?.dealer_score ?? 0;
    }
    setReplyText(replyTableName);
    setReplyColumn(replyColumn);
    setReplyData(Object.values(replyDataMapKey));

    // Factor (< 2023-04)
    const showroom = [];
    const sales = [];
    const delivery = [];
    const afterSale = [];

    // Factor (>= 2023-04)
    const salesInitiation = [];
    const dealerFacility = [];
    const deal = [];
    const deliveryTiming = [];

    const factorCategoriesList = [];
    console.log(ssiScore2);
    let factorTargetValue = 0;
    for (const score of ssiScore2?.scores ?? []) {
      if ((+filter.yearSelected == 2023 && +filter.monthSelected >= 4) || +filter.yearSelected > 2023) {
        salesInitiation.push(score?.sales_initiation_score ?? 0);
        dealerFacility.push(score?.dealer_facility_score ?? 0);
        deal.push(score?.deal_score ?? 0);
        sales.push(score?.sales_score ?? 0);
        deliveryTiming.push(score?.delivery_timing_score ?? 0);
        delivery.push(score?.delivery_score ?? 0);
      } else {
        showroom.push(score?.showroom_score ?? 0);
        sales.push(score?.sales_score ?? 0);
        delivery.push(score?.delivery_score ?? 0);
        afterSale.push(score?.after_salse_score ?? 0);
      }

      factorTargetValue = score?.target_score ?? 0;
      factorCategoriesList.push([score?.name ?? '', `(n = ${score?.n ?? 'N/A'})`]);
    }
    setFactorText(ssiScore2?.header_text ?? '');
    setFactorLegend(ssiScore2?.legend ?? []);
    setFactorShowroom(showroom);
    setFactorSales(sales);
    setFactorDelivery(delivery);
    setFactorAfterSale(afterSale);
    setFactorSalesInitiation(salesInitiation);
    setFactorDealerFacility(dealerFacility);
    setFactorDeal(deal);
    setFactorDeliveryTiming(deliveryTiming);
    setFactorCategories(factorCategoriesList);
    setFactorTarget(factorTargetValue);
    setFactorNotes([ssiScore2?.base_text ?? '', ssiScore2?.ref_text ?? '', ssiScore2?.note ?? '']);
    setFactorShow(ssiScore2?.show ?? false);

    // Factor sale
    const rowNo = 1;
    let factorSaleTargetValue = 0;
    const factorSaleLegend = ssiScore3?.legend ?? [];
    const factorSaleRowList = [];
    const factorSaleRowDataList: any = [];
    let colorIndex = 0;
    const showLegends = factorSaleLegend?.filter(
      (legend: any) => legend?.show == true && legend?.datakey != 'target_score',
    );

    for (const [index, score] of ssiScore3?.scores?.entries() ?? []) {
      factorSaleTargetValue = score?.target_score ?? 0;
      const colorsArray = ['#b3e3e3', '#fbd1b3', '#f2b3c7', '#e3d8ff', '#fef2c8', '#bbd7ff'];

      factorSaleRowList.push({
        key: index,
        title: score.name,
        ...(score.highlight && { color: colorsArray?.[colorIndex] ?? '#4d4d4d' }),
      });

      const dataForChart: any = {
        name: score.name,
      };

      showLegends?.forEach((legend: any, index: number) => {
        if (dataForChart[legend?.name]) {
          dataForChart[legend?.name + `--${index}`] = score[legend?.datakey];
        } else {
          dataForChart[legend?.name] = score[legend?.datakey];
        }
      });

      factorSaleRowDataList.push(dataForChart);
      if (score.highlight) {
        colorIndex++;
      }
    }

    const targetLegend = factorSaleLegend?.find?.((legend: any) => legend.datakey == 'target_score');

    const showLegendsWithTarget = [...showLegends];
    if (targetLegend) {
      showLegendsWithTarget.push({ ...targetLegend, ...{ type: 'line' } });
    }

    setFactorSaleText(ssiScore3?.header_text ?? '');
    setFactorSaleLegend(showLegendsWithTarget ?? []);
    setFactorSaleRow(factorSaleRowList);
    setFactorSaleRowData(factorSaleRowDataList);
    setFactorSaleTarget(factorSaleTargetValue);
    setFactorSaleNotes([ssiScore3?.base_text ?? '', ssiScore3?.ref_text ?? '', ssiScore3?.note ?? '']);
    setFactorSaleShow(ssiScore3?.show ?? false);

    // Strong/Improve
    setAttributeText(ssiScore4?.header_text ?? '');
    setAttributeLabels([ssiScore4?.strong_text ?? '', ssiScore4?.improve_text ?? '']);
    setAttributeStrong(ssiScore4?.strong_points ?? []);
    setAttributeImprove(ssiScore4?.improve_points ?? []);
    setAttributeNotes([ssiScore4?.base_text ?? '', ssiScore4?.ref_text ?? '']);
    setAttributeShow(ssiScore4?.show ?? false);

    // Satisfaction score
    setSatisfactionText(ssiScore5?.pie_chart?.header_text ?? '');
    setSatisfactionScore(ssiScore5?.pie_chart?.scores[0] ?? {});
    setSatisfactionColors(ssiScore5?.pie_chart?.color_code ?? []);
    setSatisfactionShow(ssiScore5?.show ?? false);

    // Purpose
    const purposeCategoriesList = [];
    const purposeScoreList: any = [
      {
        name: ssiScore5?.bar_chart?.legend[0]?.name ?? '',
        score: [],
        n: ssiScore5?.bar_chart?.legend[0]?.n ?? 0,
        total: 100,
      },
      {
        name: ssiScore5?.bar_chart?.legend[1]?.name ?? '',
        score: [],
        n: ssiScore5?.bar_chart?.legend[1]?.n ?? 0,
        total: 100,
      },
    ];
    for (const score of ssiScore5?.bar_chart?.scores ?? []) {
      purposeCategoriesList.push(score.name);
      purposeScoreList[0].score.push(score.nationwide_score);
      purposeScoreList[1].score.push(score.dealer_score);
    }
    setPurposeText(ssiScore5?.bar_chart?.header_text ?? '');
    setPurposeLegend([
      { name: ssiScore5?.bar_chart?.legend[0]?.name ?? '', n: `n=${ssiScore5?.bar_chart?.legend[0]?.n ?? 'N/A'}` },
      { name: ssiScore5?.bar_chart?.legend[1]?.name ?? '', n: `n=${ssiScore5?.bar_chart?.legend[1]?.n ?? 'N/A'}` },
    ]);
    setPurposeNotes([ssiScore5?.bar_chart?.base_text ?? '', ssiScore5?.bar_chart?.ref_text ?? '']);
    setPurposeCategories(purposeCategoriesList);
    setPurposeScore(purposeScoreList);

    // Problem
    const problemCategoriesList = [];
    const problemScoreList: any = [
      {
        name: ssiScore6?.bar_chart?.legend?.[0]?.name ?? '',
        score: [],
        n: ssiScore6?.bar_chart?.legend?.[0]?.n ?? 0,
        total: 100,
      },
      {
        name: ssiScore6?.bar_chart?.legend?.[1]?.name ?? '',
        score: [],
        n: ssiScore6?.bar_chart?.legend?.[1]?.n ?? 0,
        total: 100,
      },
    ];
    for (const score of ssiScore6?.bar_chart?.scores ?? []) {
      problemCategoriesList.push(score.name);
      problemScoreList[0].score.push(score.nationwide_score);
      problemScoreList[1].score.push(score.dealer_score);
    }
    setProblemText(ssiScore6?.bar_chart?.header_text ?? '');
    setProblemNotes([ssiScore6?.bar_chart?.base_text ?? '', ssiScore6?.bar_chart?.ref_text ?? '']);
    setProblemLegend([
      { name: ssiScore6?.bar_chart?.legend?.[0]?.name ?? '', n: `n=${ssiScore6?.bar_chart?.legend?.[0]?.n ?? 'N/A'}` },
      { name: ssiScore6?.bar_chart?.legend?.[1]?.name ?? '', n: `n=${ssiScore6?.bar_chart?.legend?.[1]?.n ?? 'N/A'}` },
    ]);
    setProblemCategories(problemCategoriesList);
    setProblemScore(problemScoreList);
    setProblemShow(ssiScore6?.show ?? false);

    if ((+filter.yearSelected == 2023 && +filter.monthSelected >= 4) || +filter.yearSelected > 2023) {
      const formatQ30Data = [
        { name: ssiScore6?.bar_chart?.q30?.nationwide_name, ...ssiScore6?.bar_chart?.q30?.nationwide },
        {
          name: ssiScore6?.bar_chart?.q30?.dealer_name,
          ...ssiScore6?.bar_chart?.q30?.dealer,
        },
      ];

      const formatQ31Data = [
        { name: ssiScore6?.bar_chart?.q31?.nationwide_name, ...ssiScore6?.bar_chart?.q31?.nationwide },
        {
          name: ssiScore6?.bar_chart?.q31?.dealer_name,
          ...ssiScore6?.bar_chart?.q31?.dealer,
        },
      ];

      setQ30Data(formatQ30Data);
      setQ31Data(formatQ31Data);
      setQ30Topic(ssiScore6?.bar_chart?.q30?.questionnaire_text ?? '');
      setQ31Topic(ssiScore6?.bar_chart?.q31?.questionnaire_text ?? '');
      setQ30Note([ssiScore6?.bar_chart?.q30?.base_text ?? '', ssiScore6?.bar_chart?.q30?.ref_text ?? '']);
      setQ31Note([ssiScore6?.bar_chart?.q31?.base_text ?? '', ssiScore6?.bar_chart?.q31?.ref_text ?? '']);
      setQ30Top2Boxes([
        ssiScore6?.bar_chart?.q30?.nationwide_top_two_boxes ?? 0,
        ssiScore6?.bar_chart?.q30?.dealer_top_two_boxes ?? 0,
      ]);
      setQ31Top2Boxes([
        ssiScore6?.bar_chart?.q31?.nationwide_top_two_boxes ?? 0,
        ssiScore6?.bar_chart?.q31?.dealer_top_two_boxes ?? 0,
      ]);
    }
  }, [data]);

  return (
    <SSIScoreContainer>
      {(trendsShow || factorShow || factorSaleShow || attributeShow || satisfactionShow || problemShow) && (
        <Common.TitleSection>SSI Score</Common.TitleSection>
      )}
      {!((+filter.yearSelected == 2023 && +filter.monthSelected >= 4) || +filter.yearSelected > 2023) && trendsShow && (
        <>
          <Common.BoxContainer>
            <Common.TitleInCard>{trendsHeader}</Common.TitleInCard>
            <div className="mixed-chart">
              <MixedChart
                labels={trendsLabel}
                series={[
                  {
                    name: trendsLegend[0]?.name ?? '',
                    type: 'line',
                    data: trendsNationwide,
                  },
                  {
                    name: trendsLegend[1]?.name ?? '',
                    type: 'column',
                    data: trendsDealer,
                  },
                ]}
                colors={['#F06400', '#1D79FF']}
                targetLine={{ name: trendsTargetText, value: trendsTarget, color: '#0136E0' }}
              />
            </div>
            <br />
            <Table className="mixed-chart-table" dataSource={replyData} columns={replyColumn} pagination={false} />
            <br />
            <Note data={trendsNotes} />
          </Common.BoxContainer>
          <br />
        </>
      )}
      {factorShow && (
        <>
          <Common.BoxContainer>
            <Common.TitleInCard>{factorText}</Common.TitleInCard>
            <div className="line-chart">
              <LineChart
                series={
                  (+filter.yearSelected == 2023 && +filter.monthSelected >= 4) || +filter.yearSelected > 2023
                    ? [
                        {
                          name: factorLegend[0]?.name ?? '',
                          data: factorSalesInitiation,
                        },
                        {
                          name: factorLegend[1]?.name ?? '',
                          data: factorDealerFacility,
                        },
                        {
                          name: factorLegend[2]?.name ?? '',
                          data: factorDeal,
                        },
                        {
                          name: factorLegend[3]?.name ?? '',
                          data: factorSales,
                        },
                        {
                          name: factorLegend[4]?.name ?? '',
                          data: factorDeliveryTiming,
                        },
                        {
                          name: factorLegend[5]?.name ?? '',
                          data: factorDelivery,
                        },
                      ]
                    : [
                        {
                          name: factorLegend[0]?.name ?? '',
                          data: factorShowroom,
                        },
                        {
                          name: factorLegend[1]?.name ?? '',
                          data: factorSales,
                        },
                        {
                          name: factorLegend[2]?.name ?? '',
                          data: factorDelivery,
                        },
                        {
                          name: factorLegend[3]?.name ?? '',
                          data: factorAfterSale,
                        },
                      ]
                }
                categories={factorCategories}
                targetLine={{
                  name: factorLegend[factorLegend.length - 1]?.name ?? '',
                  value: factorTarget,
                  color: '#0136E0',
                }}
                colors={['#008ffb', '#00e396', '#feb019', '#ff4560', '#775dd0', '#f78ae0']}
              />
            </div>
            <br />
            <Note data={factorNotes} />
          </Common.BoxContainer>
          <br />
        </>
      )}
      {factorSaleShow && (
        <>
          <Common.BoxContainer>
            <div className="flex sb custom-legend">
              <Common.TitleInCard>{factorSaleText}</Common.TitleInCard>
              <Legends legends={factorSaleLegend} colors={['#F06400', '#1D79FF', '#00A1A2']}></Legends>
            </div>
            <br />
            <TableWithLineChart
              dataTable={factorSaleRow}
              dataChart={factorSaleRowData}
              colors={['#F06400', '#1D79FF', '#00A1A2']}
              targetLine={{
                name:
                  data?.ssi_score_1_7?.legend?.find?.((legend: any) => {
                    const regex = new RegExp('target', 'i');
                    return legend.name.match(regex);
                  })?.name ?? '',
                value: factorSaleTarget,
                color: '#0136E0',
              }}
            />
            <br />
            <Note data={factorSaleNotes} />
          </Common.BoxContainer>
          <br />
        </>
      )}
      {attributeShow && (
        <>
          <Common.BoxContainer>
            <Common.TitleInCard>{attributeText}</Common.TitleInCard>
            <br />
            <CompareTable
              strongs={attributeStrong}
              improves={attributeImprove}
              strongText={attributeLabels[0] ?? ''}
              improveText={attributeLabels[1] ?? ''}
            />
            <br />
            <Note data={attributeNotes} />
          </Common.BoxContainer>
          <br />
        </>
      )}
      {satisfactionShow && (
        <>
          <div className="flex">
            <Common.BoxContainer style={{ width: '350px', marginRight: '20px' }}>
              <Common.TitleInCard>{satisfactionText}</Common.TitleInCard>
              <br />
              <div className="flex">
                <Common.SubTitleInCard style={{ fontWeight: 300 }}>
                  {satisfactionScore?.maximum_text ?? ''}
                </Common.SubTitleInCard>
                <Common.SubTitleInCard style={{ marginLeft: 'auto' }}>
                  {satisfactionScore?.target_text ?? ''}
                </Common.SubTitleInCard>
              </div>
              <Common.SubTitleInCard style={{ fontWeight: 300 }}>
                (N = {satisfactionScore?.n ?? 'N/A'})
              </Common.SubTitleInCard>
              <br />
              <div style={{ margin: '0 50px' }}>
                <DonutChart
                  series={[
                    satisfactionScore?.score ?? 0,
                    (satisfactionScore?.total ?? 0) - (satisfactionScore?.score ?? 0),
                  ]}
                  colors={[renderColors[satisfactionScore?.color], '#CCCCCC']}
                />
              </div>
              <br />
              <Indicators
                style={{ display: 'flex', justifyContent: 'center' }}
                headers={['สี', 'SSI Score']}
                data={[
                  {
                    target_text: satisfactionColors[0]?.color ?? '',
                    target_text_color: '#00A1A2',
                    ssi: satisfactionColors[0]?.ssi ?? 'N/A',
                  },
                  {
                    target_text: satisfactionColors[1]?.color ?? '',
                    target_text_color: '#FCD447',
                    ssi: satisfactionColors[1]?.ssi ?? 'N/A',
                  },
                  {
                    target_text: satisfactionColors[2]?.color ?? '',
                    target_text_color: '#D20043',
                    ssi: satisfactionColors[2]?.ssi ?? 'N/A',
                  },
                ]}
              />
            </Common.BoxContainer>
            <Common.BoxContainer style={{ flex: '1' }}>
              <div className="flex sb">
                <Common.TitleInCard>{purposeText}</Common.TitleInCard>
                <Legends legends={purposeLegend} colors={['#F06400', '#1D79FF']} />
              </div>
              <BasicColumnChart data={purposeScore} categories={purposeCategories} colors={['#F06400', '#1D79FF']} />
              <br />
              <Note data={purposeNotes} />
            </Common.BoxContainer>
          </div>
          <br />
        </>
      )}
      {((+filter.yearSelected == 2023 && +filter.monthSelected >= 4) || +filter.yearSelected > 2023) && problemShow && (
        <>
          <Common.BoxContainer style={{ overflowX: 'scroll' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              <div style={{ flex: '1' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Common.TitleInCard style={{ height: '45px', overflow: 'hidden' }}>{Q30Topic}</Common.TitleInCard>
                  <StackBarChart data={Q30Data} />
                </div>
                <br />
                <Note data={Q30Note} />
              </div>
              <div>
                <div
                  style={{
                    color: '#5c5c5c',
                    fontSize: '1.3rem',
                    fontWeight: 300,
                  }}
                >
                  Top 2 Boxes
                </div>
                <div
                  style={{
                    color: '#5c5c5c',
                    fontSize: '1.3rem',
                    fontWeight: 300,
                    textAlign: 'center',
                    marginTop: '125px',
                  }}
                >
                  {Q30Top2Boxes?.[0] ?? 0}%
                </div>
                <div
                  style={{
                    color: '#5c5c5c',
                    fontSize: '1.3rem',
                    fontWeight: 300,
                    textAlign: 'center',
                    marginTop: '70px',
                  }}
                >
                  {Q30Top2Boxes?.[1] ?? 0}%
                </div>
              </div>
              <div style={{ flex: '1' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Common.TitleInCard style={{ height: '45px', overflow: 'hidden' }}>{Q31Topic}</Common.TitleInCard>
                  <StackBarChart data={Q31Data} />
                </div>
                <br />
                <Note data={Q31Note} />
              </div>
              <div>
                <div
                  style={{
                    color: '#5c5c5c',
                    fontSize: '1.3rem',
                    fontWeight: 300,
                  }}
                >
                  Top 2 Boxes
                </div>
                <div
                  style={{
                    color: '#5c5c5c',
                    fontSize: '1.3rem',
                    fontWeight: 300,
                    textAlign: 'center',
                    marginTop: '125px',
                  }}
                >
                  {Q31Top2Boxes?.[0] ?? 0}%
                </div>
                <div
                  style={{
                    color: '#5c5c5c',
                    fontSize: '1.3rem',
                    fontWeight: 300,
                    textAlign: 'center',
                    marginTop: '70px',
                  }}
                >
                  {Q31Top2Boxes?.[1] ?? 0}%
                </div>
              </div>
            </div>
          </Common.BoxContainer>
          <br />
        </>
      )}
      {!((+filter.yearSelected == 2023 && +filter.monthSelected >= 4) || +filter.yearSelected > 2023) && problemShow && (
        <>
          <Common.BoxContainer>
            <Common.TitleInCard>{problemText}</Common.TitleInCard>
            <Legends style={{ justifyContent: 'flex-end' }} legends={problemLegend} colors={['#F06400', '#1D79FF']} />
            <BasicColumnChart data={problemScore} categories={problemCategories} colors={['#F06400', '#1D79FF']} />
            <br />
            <Note data={problemNotes} />
          </Common.BoxContainer>
          <br />
        </>
      )}
      {data?.ssi_bubble_chart_7_7?.show && (
        <>
          <Common.BoxContainer>
            <Common.TitleInCard>{data?.ssi_bubble_chart_7_7?.header_text}</Common.TitleInCard>
            <br />
            <div className="filter-bubble-group">
              <Select
                value={filter.ssiBubbleS1Selected}
                onChange={(value) => dispatch(ssiBubbleS1Selected(value))}
                placeholder="Select"
              >
                {filter?.ssiBubbleS1?.map?.((data) => {
                  return (
                    <Select.Option key={data.id} value={data.id} disabled={!!+data.id}>
                      {data.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
            <br />
            <div className="flex" style={{ width: '100%' }}>
              {data?.ssi_bubble_chart_7_7?.bubble?.map?.((chart: any, index: number) => (
                <div key={chart?.color + index} style={{ flex: '1', minWidth: '0' }}>
                  <div style={{ height: '650px', marginBottom: '10px', backgroundColor: chart?.bgColor }}>
                    <BubbleChart data={chart?.data} color={chart?.color} />
                  </div>
                  <div style={{ fontSize: '1.12rem', fontWeight: 500, textAlign: 'center' }}>{chart?.section_text}</div>
                </div>
              ))}
            </div>
          </Common.BoxContainer>
        </>
      )}
    </SSIScoreContainer>
  );
};

export default SSIScore;
