import styled from '@emotion/styled/macro';

export const Container = styled.div`
  .grid-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin-bottom: 40px;

    > div:last-of-type {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }

  .grid-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
  }

  .column-chart {
    height: 300px;
  }

  .flex {
    display: flex;
  }

  .bar-chart {
    min-width: 0;
    flex: 1;
    min-height: 200px;
  }
`;
