import { useMemo } from 'react';
import { Table } from 'antd';
import { TableContainer } from './style';
import { ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Line, ReferenceLine } from 'recharts';

type PropsType = {
  dataTable: any[];
  dataChart: any[];
  colors?: any[];
  targetLine?: { name: string; color: string; value: number };
};

const RenderCustomLabel = ({ fill, value, textAnchor, fontSize, viewBox, dy, dx, color }: any) => {
  const width = value.length * 6.5;
  let x = viewBox.x + -width;
  const y = 45;

  if (viewBox.x < width) {
    x = viewBox.x;
  }

  return (
    <g>
      <foreignObject x={x} y={y} width={width} height={100}>
        <div
          style={{
            backgroundColor: color,
            color: '#fff',
            borderRadius: '3px',
            fontSize: '11px',
            textAlign: 'center',
            padding: '2px 0',
          }}
        >
          {value}
        </div>
      </foreignObject>
    </g>
  );
};

const calTickInterval = (range: number) => {
  let steps = [100, 75, 50, 25, 5];
  if (range <= 50) {
    return 5;
  } else if (range <= 500) {
    return steps.find((step) => range % step == 0) ?? 25;
  } else if (range <= 800) {
    steps = [...[125], ...steps];
    return steps.find((step) => range % step == 0) ?? 25;
  } else {
    steps = [...[200, 175, 150, 125], ...steps];
    return steps.find((step) => range % step == 0) ?? 25;
  }
};

const TableWithLineChart = ({ dataTable, dataChart, colors, targetLine }: PropsType) => {
  const columns = useMemo(() => {
    const max = 1000;
    const data = dataChart.map((row) => {
      const newObj = { ...row };
      delete newObj.name;

      let array: number[] = Object.values(newObj) ?? [];
      array = array.filter((n) => n != undefined || n != null);

      return Math.min(...array);
    });

    let minDomain = Math.min(...data) < (targetLine?.value ?? 0) ? Math.min(...data) : targetLine?.value ?? 0;

    const fractionOfMinDomain = minDomain % 25;

    if (fractionOfMinDomain) {
      minDomain = minDomain - fractionOfMinDomain <= 0 ? minDomain : minDomain - fractionOfMinDomain;
    }

    const diffMinAndMax = max - minDomain;

    const tickArray: any[] = [];

    const tickInterval = calTickInterval(diffMinAndMax);

    for (let index = minDomain; index <= max; index = index + tickInterval) {
      tickArray.push(index);
    }

    return [
      {
        title: 'Name',
        dataIndex: 'title',
        key: 'title',
        width: 600,
        render: (value: any, record: any) => {
          return {
            props: {
              style: { background: record?.color, fontWeight: record?.color && 'bold' },
            },
            children: (
              <div
                style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '570px' }}
                dangerouslySetInnerHTML={{ __html: value }}
              ></div>
            ),
          };
        },
      },
      {
        title: '',
        dataIndex: 'chart',
        key: 'chart',
        render: (value: any, record: any, index: any) => {
          if (index === 0) {
            return {
              props: {
                style: { background: record?.color },
              },
              children: (
                <div className="chart-in-table">
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart layout="vertical" data={dataChart} margin={{ top: 0, right: 60, left: 20, bottom: 0 }}>
                      <CartesianGrid strokeDasharray="5 5" horizontal={false} />
                      <XAxis orientation="top" type="number" domain={[minDomain, 1000]} mirror ticks={tickArray} />
                      <XAxis
                        orientation="bottom"
                        type="number"
                        domain={[minDomain, 1000]}
                        mirror
                        xAxisId={1}
                        ticks={tickArray}
                      />
                      <YAxis dataKey="name" type="category" hide padding={{ top: 30, bottom: 30 }} />
                      <ReferenceLine
                        x={targetLine?.value}
                        stroke={targetLine?.color}
                        strokeWidth={2}
                        strokeDasharray="8 8"
                        label={<RenderCustomLabel value={targetLine?.name} color={targetLine?.color} />}
                      />
                      <Tooltip />
                      {Object.keys(dataChart?.[0])
                        .filter((key) => key !== 'name')
                        .map?.((key, index) => {
                          return (
                            <Line
                              key={key}
                              dataKey={key}
                              name={key.replace(/--[0-99]/g, '')}
                              isAnimationActive={false}
                              strokeWidth={4}
                              stroke={colors?.[index]}
                              dot={{ stroke: colors?.[index], fill: colors?.[index], strokeWidth: 3 }}
                            />
                          );
                        })}
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              ),
            };
          }
          return {
            props: {
              style: { background: record?.color },
            },
            children: value,
          };
        },
      },
    ];
  }, [dataChart]);

  return (
    <TableContainer>
      <Table dataSource={dataTable} columns={columns} pagination={false} showHeader={false}></Table>
    </TableContainer>
  );
};

export default TableWithLineChart;
