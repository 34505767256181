import { useEffect, useState } from 'react';
import * as Common from 'components/common';
import MixedChart from 'components/mixedChart';
import LineChart from 'components/lineChart';
import TableWithLineChart from 'components/tableWithLineChart';
import Legends from 'components/legends';
import CompareTable from 'components/compareTable';
import Note from 'components/note';
import DonutChart from 'components/donutChart';
import Indicators from 'components/indicators';
import BasicColumnChart from 'components/basicColumnChart';
import BubbleChart from 'components/bubbleChart';
import { SSIScoreContainer } from './style';
import { Table, Select } from 'antd';
import { renderColors } from 'utils/renderColors';
import { useAppDispatch, useAppSelector } from 'store';
import { csiBubbleS1Selected } from 'store/filterSlice';

const CSIScore = ({ data }: any) => {
  const dispatch = useAppDispatch();
  const filter = useAppSelector((state) => state.filter);
  const [trendsHeader, setTrendsHeader] = useState('');
  const [trendsLabel, setTrendsLabel] = useState([]);
  const [trendsNationwide, setTrendsNationwide] = useState<number[]>([]);
  const [trendsDealer, setTrendsDealer] = useState<number[]>([]);
  const [trendsTarget, setTrendsTarget] = useState(0);
  const [trendsTargetText, setTrendsTargetText] = useState('');
  const [trendsLegend, setTrendsLegend] = useState<any>([]);
  const [trendsNotes, setTrendsNotes] = useState<string[]>([]);
  const [trendsShow, setTrendsShow] = useState(false);
  const [replyText, setReplyText] = useState('');
  const [replyColumn, setReplyColumn] = useState([]);
  const [replyData, setReplyData] = useState([]);
  const [factorText, setFactorText] = useState('');
  const [factorTarget, setFactorTarget] = useState(0);
  const [factorLegend, setFactorLegend] = useState<any>([]);
  const [factorServiceStart, setFactorServiceStart] = useState<number[]>([]);
  const [factorFacilities, setFactorFacilities] = useState<number[]>([]);
  const [factorServiceQuality, setFactorServiceQuality] = useState<number[]>([]);
  const [factorServiceExecutive, setFactorServiceExecutive] = useState<number[]>([]);
  const [factorDelivery, setFactorDelivery] = useState<number[]>([]);
  const [factorFollowUp, setFactorFollowUp] = useState<number[]>([]);
  const [factorCategories, setFactorCategories] = useState<any>([]);
  const [factorNotes, setFactorNotes] = useState<string[]>([]);
  const [factorShow, setFactorShow] = useState(false);
  const [factorSaleText, setFactorSaleText] = useState('');
  const [factorSaleLegend, setFactorSaleLegend] = useState<any>([]);
  const [factorSaleRow, setFactorSaleRow] = useState<any>([]);
  const [factorSaleRowData, setFactorSaleRowData] = useState<any>([]);
  const [factorSaleTarget, setFactorSaleTarget] = useState(0);
  const [factorSaleNotes, setFactorSaleNotes] = useState<string[]>([]);
  const [factorSaleShow, setFactorSaleShow] = useState(false);
  const [attributeText, setAttributeText] = useState('');
  const [attributeLabels, setAttributeLabels] = useState<string[]>([]);
  const [attributeStrong, setAttributeStrong] = useState([]);
  const [attributeImprove, setAttributeImprove] = useState([]);
  const [attributeNotes, setAttributeNotes] = useState<string[]>([]);
  const [attributeShow, setAttributeShow] = useState(false);
  const [satisfaction, setSatisfaction] = useState<any>({});
  const [satisfactionShow, setSatisfactionShow] = useState(false);
  const [satisfactionService, setSatisfactionService] = useState<any>({});
  const [satisfactionServiceShow, setSatisfactionServiceShow] = useState(false);

  useEffect(() => {
    const csiScore = data?.csi_score_1_7 ?? undefined;
    const csiScore2 = data?.csi_score_2_7 ?? undefined;
    const csiScore3 = data?.csi_score_3_7 ?? undefined;
    const csiScore4 = data?.csi_score_4_7 ?? undefined;
    const csiScore5 = data?.csi_score_5_7 ?? undefined;
    const csiScore6 = data?.csi_score_6_7 ?? undefined;

    // Trends
    const nationwide: number[] = [];
    const dealer: number[] = [];
    let target = 0;
    const labels =
      csiScore?.scores?.map?.((score: any) => {
        nationwide.push(score?.nationwide_score ?? 0);
        dealer.push(score?.dealer_score ?? 0);
        target = score?.target_score ?? 0;

        return score?.name ?? '';
      }) ?? [];
    setTrendsHeader(csiScore?.header_text ?? '');
    setTrendsLabel(labels);
    setTrendsNationwide(nationwide);
    setTrendsDealer(dealer);
    setTrendsTarget(target);
    setTrendsTargetText(
      csiScore?.legend?.find?.((legend: any) => {
        const regex = new RegExp('target', 'i');
        return legend.name.match(regex);
      })?.name ?? '',
    );
    setTrendsLegend(csiScore?.legend ?? []);
    setTrendsNotes([csiScore?.base_text ?? '', csiScore?.ref_text ?? '']);
    setTrendsShow(csiScore?.show ?? false);

    // Reply
    const replyTableName = csiScore?.table_n_text ?? '';
    const replyColumn: any = [
      {
        title: replyTableName || 'จำนวนตอบกลับ',
        dataIndex: 'name',
        key: 'name',
        width: 173,
      },
    ];
    const replyDataMapKey: any = {
      nation: { key: '1', name: csiScore?.legend[0]?.name },
      dealer: { key: '2', name: csiScore?.legend[1]?.name },
    };
    for (const [index, score] of csiScore?.table_n?.entries() ?? []) {
      const idx = `reply-${index}`;

      replyColumn.push({
        title: score?.name ?? '',
        dataIndex: idx,
        key: idx,
        align: 'center',
      });
      replyDataMapKey.nation[idx] = score?.nationwide_score ?? 0;
      replyDataMapKey.dealer[idx] = score?.dealer_score ?? 0;
    }
    setReplyText(replyTableName);
    setReplyColumn(replyColumn);
    setReplyData(Object.values(replyDataMapKey));

    // Factor
    const serviceStart = [];
    const facilities = [];
    const serviceQuality = [];
    const serviceExecutive = [];
    const delivery = [];
    const followUp = [];
    const factorCategoriesList = [];
    let factorTargetValue = 0;
    for (const score of csiScore2?.scores ?? []) {
      serviceStart.push(score?.service_start_score ?? 0);
      facilities.push(score?.facilities_score ?? 0);
      serviceQuality.push(score?.service_quality_score ?? 0);
      serviceExecutive.push(score?.service_executive_score ?? 0);
      delivery.push(score?.delivery_score ?? 0);
      followUp.push(score?.follow_up_score ?? 0);
      factorTargetValue = score?.target_score ?? 0;
      factorCategoriesList.push([score?.name ?? '', `(n = ${score?.n ?? 'N/A'})`]);
    }
    setFactorText(csiScore2?.header_text ?? '');
    setFactorLegend(csiScore2?.legend ?? []);
    setFactorServiceStart(serviceStart);
    setFactorFacilities(facilities);
    setFactorServiceQuality(serviceQuality);
    setFactorServiceExecutive(serviceExecutive);
    setFactorDelivery(delivery);
    setFactorFollowUp(followUp);
    setFactorCategories(factorCategoriesList);
    setFactorTarget(factorTargetValue);
    setFactorNotes([csiScore2?.base_text ?? '', csiScore2?.ref_text ?? '', csiScore2?.note ?? '']);
    setFactorShow(csiScore2?.show ?? false);

    // Factor sale
    const rowNo = 1;
    let factorSaleTargetValue = 0;
    const factorSaleLegend = csiScore3?.legend ?? [];
    const factorSaleRowList = [];
    const factorSaleRowDataList: any = [];
    let colorIndex = 0;
    const showLegends = factorSaleLegend?.filter(
      (legend: any) => legend?.show == true && legend?.datakey != 'target_score',
    );

    for (const [index, score] of csiScore3?.scores?.entries() ?? []) {
      factorSaleTargetValue = score?.target_score ?? 0;
      const colorsArray = ['#b3e3e3', '#fbd1b3', '#f2b3c7', '#e3d8ff', '#fef2c8', '#bbd7ff'];

      factorSaleRowList.push({
        key: index,
        title: score.name,
        ...(score.highlight && { color: colorsArray?.[colorIndex] ?? '#4d4d4d' }),
      });

      const dataForChart = {
        name: score.name,
      };

      showLegends?.forEach((legend: any, index: number) => {
        if (dataForChart[legend?.name]) {
          dataForChart[legend?.name + `--${index}`] = score[legend?.datakey];
        } else {
          dataForChart[legend?.name] = score[legend?.datakey];
        }
      });

      factorSaleRowDataList.push(dataForChart);
      if (score.highlight) {
        colorIndex++;
      }
    }

    const targetLegend = factorSaleLegend?.find?.((legend: any) => legend.datakey == 'target_score');

    const showLegendsWithTarget = [...showLegends];
    if (targetLegend) {
      showLegendsWithTarget.push({ ...targetLegend, ...{ type: 'line' } });
    }

    setFactorSaleText(csiScore3?.header_text ?? '');
    setFactorSaleLegend(showLegendsWithTarget ?? []);
    setFactorSaleRow(factorSaleRowList);
    setFactorSaleRowData(factorSaleRowDataList);
    setFactorSaleTarget(factorSaleTargetValue);
    setFactorSaleNotes([csiScore3?.base_text ?? '', csiScore3?.ref_text ?? '', csiScore3?.note ?? '']);
    setFactorSaleShow(csiScore3?.show ?? false);

    // Strong/Improve
    setAttributeText(csiScore4?.header_text ?? '');
    setAttributeLabels([csiScore4?.strong_text ?? '', csiScore4?.improve_text ?? '']);
    setAttributeStrong(csiScore4?.strong_points ?? []);
    setAttributeImprove(csiScore4?.improve_points ?? []);
    setAttributeNotes([csiScore4?.base_text ?? '', csiScore4?.ref_text ?? '']);
    setAttributeShow(csiScore4?.show ?? false);

    // Satisfaction
    setSatisfaction(csiScore5);
    setSatisfactionShow(csiScore5?.show ?? false);

    // Satisfaction Service
    setSatisfactionService(csiScore6);
    setSatisfactionServiceShow(csiScore6?.show ?? false);
  }, [data]);

  return (
    <SSIScoreContainer>
      {(trendsShow || factorShow || factorSaleShow || attributeShow || satisfactionShow || satisfactionServiceShow) && (
        <Common.TitleSection>CSI Score</Common.TitleSection>
      )}
      {trendsShow && (
        <>
          <Common.BoxContainer>
            <Common.TitleInCard>{trendsHeader}</Common.TitleInCard>
            <div className="mixed-chart">
              <MixedChart
                labels={trendsLabel}
                series={[
                  {
                    name: trendsLegend[0]?.name ?? '',
                    type: 'line',
                    data: trendsNationwide,
                  },
                  {
                    name: trendsLegend[1]?.name ?? '',
                    type: 'column',
                    data: trendsDealer,
                  },
                ]}
                colors={['#F06400', '#1D79FF']}
                targetLine={{ name: trendsTargetText, value: trendsTarget, color: '#0136E0' }}
              />
            </div>
            <br />
            <Table className="mixed-chart-table" dataSource={replyData} columns={replyColumn} pagination={false} />
            <br />
            <Note data={trendsNotes} />
          </Common.BoxContainer>
          <br />
        </>
      )}
      {factorShow && (
        <>
          <Common.BoxContainer>
            <Common.TitleInCard>{factorText}</Common.TitleInCard>
            <div className="line-chart">
              <LineChart
                series={[
                  {
                    name: factorLegend[0]?.name ?? '',
                    data: factorServiceStart,
                  },
                  {
                    name: factorLegend[2]?.name ?? '',
                    data: factorFacilities,
                  },
                  {
                    name: factorLegend[4]?.name ?? '',
                    data: factorServiceQuality,
                  },
                  {
                    name: factorLegend[1]?.name ?? '',
                    data: factorServiceExecutive,
                  },
                  {
                    name: factorLegend[3]?.name ?? '',
                    data: factorDelivery,
                  },
                  {
                    name: factorLegend[5]?.name ?? '',
                    data: factorFollowUp,
                  },
                ]}
                categories={factorCategories}
                colors={['#00a1a2', '#1d79ff', '#d20043', '#f06400', '#6512f0', '#f84e83']}
                targetLine={{ name: factorLegend[6]?.name ?? '', value: factorTarget, color: '#0136E0' }}
              />
            </div>
            <br />
            <Note data={factorNotes} />
          </Common.BoxContainer>
          <br />
        </>
      )}
      {factorSaleShow && (
        <>
          <Common.BoxContainer>
            <div className="flex sb custom-legend">
              <Common.TitleInCard>{factorSaleText}</Common.TitleInCard>
              <Legends legends={factorSaleLegend} colors={['#F06400', '#1D79FF', '#00A1A2']}></Legends>
            </div>
            <br />
            <TableWithLineChart
              dataTable={factorSaleRow}
              dataChart={factorSaleRowData}
              colors={['#F06400', '#1D79FF', '#00A1A2']}
              targetLine={{
                name:
                  data?.csi_score_1_7?.legend?.find?.((legend: any) => {
                    const regex = new RegExp('target', 'i');
                    return legend.name.match(regex);
                  })?.name ?? '',
                value: factorSaleTarget,
                color: '#0136E0',
              }}
            />
            <br />
            <Note data={factorSaleNotes} />
          </Common.BoxContainer>
          <br />
        </>
      )}
      {attributeShow && (
        <>
          <Common.BoxContainer>
            <Common.TitleInCard>{attributeText}</Common.TitleInCard>
            <br />
            <CompareTable
              strongs={attributeStrong}
              improves={attributeImprove}
              strongText={attributeLabels[0] ?? ''}
              improveText={attributeLabels[1] ?? ''}
            />
            <br />
            <Note data={attributeNotes} />
          </Common.BoxContainer>
          <br />
        </>
      )}
      {satisfactionShow && (
        <>
          <div className="flex">
            <Common.BoxContainer style={{ flex: '1', marginRight: '20px' }}>
              <Common.TitleInCard>{satisfaction?.pie_chart?.header_text ?? ''}</Common.TitleInCard>
              <br />
              <Common.SubTitleInCard style={{ textAlign: 'right' }}>
                {satisfaction?.pie_chart?.scores?.[0]?.target_text ?? ''}
              </Common.SubTitleInCard>
              <br />
              <div className="flex">
                <div style={{ flex: '1' }}>
                  <Common.SubTitleInCard style={{ fontWeight: 300 }}>
                    {satisfaction?.pie_chart?.scores?.[0]?.maximum_text ?? ''}
                  </Common.SubTitleInCard>
                  <Common.SubTitleInCard style={{ fontSize: '1.3rem', color: '#F06400' }}>
                    {satisfaction?.pie_chart?.scores?.[0]?.name ?? ''}
                  </Common.SubTitleInCard>
                  <Common.SubTitleInCard style={{ fontWeight: 300 }}>
                    (N = {satisfaction?.pie_chart?.scores?.[0]?.n ?? 'N/A'})
                  </Common.SubTitleInCard>
                  <br />
                  <div style={{ margin: '0 30px' }}>
                    <DonutChart
                      series={[
                        satisfaction?.pie_chart?.scores?.[0]?.score ?? 0,
                        (satisfaction?.pie_chart?.scores?.[0]?.total ?? 0) -
                          (satisfaction?.pie_chart?.scores?.[0]?.score ?? 0),
                      ]}
                      colors={[renderColors[satisfaction?.pie_chart?.scores?.[0]?.color], '#CCCCCC']}
                    />
                  </div>
                </div>
                <div style={{ flex: '1' }}>
                  <Common.SubTitleInCard style={{ fontWeight: 300 }}>
                    {satisfaction?.pie_chart?.scores?.[1]?.maximum_text ?? ''}
                  </Common.SubTitleInCard>
                  <Common.SubTitleInCard style={{ fontSize: '1.3rem', color: '#1D79FF' }}>
                    {satisfaction?.pie_chart?.scores?.[1]?.name ?? ''}
                  </Common.SubTitleInCard>
                  <Common.SubTitleInCard style={{ fontWeight: 300 }}>
                    (N = {satisfaction?.pie_chart?.scores?.[1]?.n ?? 'N/A'})
                  </Common.SubTitleInCard>
                  <br />
                  <div style={{ margin: '0 30px' }}>
                    <DonutChart
                      series={[
                        satisfaction?.pie_chart?.scores?.[1]?.score ?? 0,
                        (satisfaction?.pie_chart?.scores?.[1]?.total ?? 0) -
                          (satisfaction?.pie_chart?.scores?.[1]?.score ?? 0),
                      ]}
                      colors={[renderColors[satisfaction?.pie_chart?.scores?.[1]?.color], '#CCCCCC']}
                    />
                  </div>
                </div>
              </div>
              <br />
              <Indicators
                style={{ display: 'flex', justifyContent: 'center' }}
                headers={['สี', 'CSI Score']}
                data={[
                  {
                    target_text: satisfaction?.pie_chart?.color_code?.[0]?.color ?? '',
                    target_text_color: '#00A1A2',
                    csi: satisfaction?.pie_chart?.color_code?.[0]?.csi ?? 'N/A',
                  },
                  {
                    target_text: satisfaction?.pie_chart?.color_code?.[1]?.color ?? '',
                    target_text_color: '#FCD447',
                    csi: satisfaction?.pie_chart?.color_code?.[1]?.csi ?? 'N/A',
                  },
                  {
                    target_text: satisfaction?.pie_chart?.color_code?.[2]?.color ?? '',
                    target_text_color: '#D20043',
                    csi: satisfaction?.pie_chart?.color_code?.[2]?.csi ?? 'N/A',
                  },
                ]}
              />
            </Common.BoxContainer>
            <Common.BoxContainer style={{ flex: '1' }}>
              <div className="flex sb">
                <Common.TitleInCard>{satisfaction?.bar_chart?.header_text ?? ''}</Common.TitleInCard>
                <Legends
                  legends={[
                    { name: satisfaction?.bar_chart?.legend?.[0]?.name ?? '' },
                    { name: satisfaction?.bar_chart?.legend?.[1]?.name ?? '' },
                  ]}
                  colors={['#F06400', '#1D79FF']}
                />
              </div>
              <div className="flex" style={{ height: '420px' }}>
                <div style={{ flex: '1.2', borderRight: '1px solid #ccc' }}>
                  <BasicColumnChart
                    data={[
                      {
                        name: satisfaction?.bar_chart?.legend?.[0]?.name ?? '',
                        score: [satisfaction?.bar_chart?.scores?.[0]?.nationwide_score ?? 0],
                        n: satisfaction?.bar_chart?.legend?.[0]?.n ?? 'N/A',
                        total: satisfaction?.bar_chart?.scores?.[0]?.total ?? 0,
                      },
                      {
                        name: satisfaction?.bar_chart?.legend?.[1]?.name ?? '',
                        score: [satisfaction?.bar_chart?.scores?.[0]?.dealer_score ?? 0],
                        n: satisfaction?.bar_chart?.legend?.[1]?.n ?? 'N/A',
                        total: satisfaction?.bar_chart?.scores?.[0]?.total ?? 0,
                      },
                    ]}
                    categories={[
                      [
                        satisfaction?.bar_chart?.scores?.[0]?.name ?? '',
                        `(N=${satisfaction?.bar_chart?.legend?.[0]?.n ?? 'N/A'})`,
                      ],
                    ]}
                    colors={['#F06400', '#1D79FF']}
                  />
                </div>
                <div style={{ flex: '1' }}>
                  <BasicColumnChart
                    disabledYAxis
                    data={[
                      {
                        name: satisfaction?.bar_chart?.legend?.[0]?.name ?? '',
                        score: [satisfaction?.bar_chart?.scores?.[1]?.nationwide_score ?? 0],
                        n: satisfaction?.bar_chart?.legend?.[0]?.n ?? 'N/A',
                        total: satisfaction?.bar_chart?.scores?.[1]?.total ?? 0,
                      },
                      {
                        name: satisfaction?.bar_chart?.legend?.[1]?.name ?? '',
                        score: [satisfaction?.bar_chart?.scores?.[1]?.dealer_score ?? 0],
                        n: satisfaction?.bar_chart?.legend?.[1]?.n ?? 'N/A',
                        total: satisfaction?.bar_chart?.scores?.[1]?.total ?? 0,
                      },
                    ]}
                    categories={[
                      [
                        satisfaction?.bar_chart?.scores?.[1]?.name ?? '',
                        `(N=${satisfaction?.bar_chart?.legend?.[1]?.n ?? 'N/A'})`,
                      ],
                    ]}
                    colors={['#F06400', '#1D79FF']}
                  />
                </div>
              </div>
              <br />
              <Note data={[satisfaction?.bar_chart?.base_text ?? '', satisfaction?.bar_chart?.ref_text ?? '']} />
            </Common.BoxContainer>
          </div>
          <br />
        </>
      )}
      {satisfactionServiceShow && (
        <>
          <div className="flex">
            <Common.BoxContainer style={{ flex: '1', marginRight: '20px' }}>
              <Common.TitleInCard>{satisfactionService?.pie_chart?.header_text ?? ''}</Common.TitleInCard>
              <br />
              <div className="flex">
                <Common.SubTitleInCard style={{ fontWeight: 300 }}>
                  {satisfactionService?.pie_chart?.scores?.[0]?.maximum_text ?? ''}
                </Common.SubTitleInCard>
                <Common.SubTitleInCard style={{ marginLeft: 'auto' }}>
                  {satisfactionService?.pie_chart?.scores?.[0]?.target_text ?? ''}
                </Common.SubTitleInCard>
              </div>
              <Common.SubTitleInCard style={{ fontWeight: 300 }}>
                (N = {satisfactionService?.pie_chart?.scores?.[0]?.n ?? 'N/A'})
              </Common.SubTitleInCard>
              <br />
              <div style={{ margin: '0 auto', maxWidth: '280px' }}>
                <DonutChart
                  series={[
                    satisfactionService?.pie_chart?.scores?.[0]?.score ?? 0,
                    (satisfactionService?.pie_chart?.scores?.[0]?.total ?? 0) -
                      (satisfactionService?.pie_chart?.scores?.[0]?.score ?? 0),
                  ]}
                  colors={[renderColors[satisfactionService?.pie_chart?.scores?.[0]?.color], '#CCCCCC']}
                />
              </div>
              <br />
              <Indicators
                style={{ display: 'flex', justifyContent: 'center' }}
                headers={['สี', 'CSI Score']}
                data={[
                  {
                    target_text: satisfactionService?.pie_chart?.color_code?.[0]?.color ?? '',
                    target_text_color: '#00A1A2',
                    csi: satisfactionService?.pie_chart?.color_code?.[0]?.csi ?? 'N/A',
                  },
                  {
                    target_text: satisfactionService?.pie_chart?.color_code?.[1]?.color ?? '',
                    target_text_color: '#FCD447',
                    csi: satisfactionService?.pie_chart?.color_code?.[1]?.csi ?? 'N/A',
                  },
                  {
                    target_text: satisfactionService?.pie_chart?.color_code?.[2]?.color ?? '',
                    target_text_color: '#D20043',
                    csi: satisfactionService?.pie_chart?.color_code?.[2]?.csi ?? 'N/A',
                  },
                ]}
              />
            </Common.BoxContainer>
            <Common.BoxContainer style={{ flex: '1' }}>
              <Common.TitleInCard>{satisfactionService?.bar_chart?.header_text ?? ''}</Common.TitleInCard>
              <Legends
                style={{ justifyContent: 'flex-end' }}
                legends={[
                  {
                    name: satisfactionService?.bar_chart?.legend?.[0]?.name ?? '',
                    n: `n=${satisfactionService?.bar_chart?.legend?.[0]?.n}`,
                  },
                  {
                    name: satisfactionService?.bar_chart?.legend?.[1]?.name ?? '',
                    n: `n=${satisfactionService?.bar_chart?.legend?.[1]?.n}`,
                  },
                ]}
                colors={['#F06400', '#1D79FF']}
              />
              <div style={{ height: '400px' }}>
                <BasicColumnChart
                  data={[
                    {
                      name: satisfactionService?.bar_chart?.legend?.[0]?.name ?? '',
                      score: [satisfactionService?.bar_chart?.scores?.[0]?.nationwide_score ?? 0],
                      n: satisfactionService?.bar_chart?.legend?.[0]?.n ?? 'N/A',
                      total: satisfactionService?.bar_chart?.scores?.[0]?.total ?? 0,
                    },
                    {
                      name: satisfactionService?.bar_chart?.legend?.[1]?.name ?? '',
                      score: [satisfactionService?.bar_chart?.scores?.[0]?.dealer_score ?? 0],
                      n: satisfactionService?.bar_chart?.legend?.[1]?.n ?? 'N/A',
                      total: satisfactionService?.bar_chart?.scores?.[0]?.total ?? 0,
                    },
                  ]}
                  categories={[satisfactionService?.bar_chart?.scores?.[0]?.name]}
                  colors={['#F06400', '#1D79FF']}
                />
              </div>
              <br />
              <Note
                data={[satisfactionService?.bar_chart?.base_text ?? '', satisfactionService?.bar_chart?.ref_text ?? '']}
              />
            </Common.BoxContainer>
          </div>
          <br />
        </>
      )}
      {data?.csi_bubble_chart_7_7?.show && (
        <>
          <Common.BoxContainer>
            <Common.TitleInCard>{data?.csi_bubble_chart_7_7?.header_text}</Common.TitleInCard>
            <br />
            <div className="filter-bubble-group">
              <Select
                value={filter.csiBubbleS1Selected}
                onChange={(value) => dispatch(csiBubbleS1Selected(value))}
                placeholder="Select"
              >
                {filter?.csiBubbleS1?.map?.((data) => {
                  return (
                    <Select.Option key={data.id} value={data.id} disabled={!!+data.id}>
                      {data.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
            <br />
            <div className="flex" style={{ width: '100%' }}>
              {data?.csi_bubble_chart_7_7?.bubble?.map?.((chart: any, index: number) => (
                <div key={chart?.color + index} style={{ flex: '1', minWidth: '0' }}>
                  <div style={{ height: '650px', marginBottom: '10px', backgroundColor: chart?.bgColor }}>
                    <BubbleChart data={chart?.data} color={chart?.color} />
                  </div>
                  <div style={{ fontSize: '1.12rem', fontWeight: 500, textAlign: 'center' }}>{chart?.section_text}</div>
                </div>
              ))}
            </div>
          </Common.BoxContainer>
        </>
      )}
    </SSIScoreContainer>
  );
};

export default CSIScore;
