import { NoteContainer } from './style';

const Note = ({ data }: { data: string[] }) => {
  return (
    <NoteContainer>
      {data.map?.((text) => (
        <div key={text}>{text}</div>
      ))}
    </NoteContainer>
  );
};

export default Note;
