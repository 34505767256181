import styled from '@emotion/styled/macro';

export const LoginContainer = styled.div`
  display: flex;
  min-height: 100vh;

  .login-form-forgot {
    float: right;
  }
  .ant-col-rtl .login-form-forgot {
    float: left;
  }
  .ant-form-item-label {
    font-weight: 500;
  }
  .login-form-button {
    width: 100%;
    background: rgba(23, 68, 33, 1);
    border-radius: 20px;
  }
  .ant-input-affix-wrapper {
    border-radius: 10px;
  }
  .ant-form-item-explain {
    font-size: 12px;
  }

  .left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    min-width: 300px;

    img {
      width: 60%;
    }
  }

  .right {
    flex: 3;
    display: flex;
    align-items: center;
    justify-content: center;

    .login-box {
      width: 300px;
    }
  }

  .header-login-box {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 25px;
  }
`;
