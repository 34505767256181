import { MeanContainer } from './style';

type MeanType = {
  data: { definition_text: string; ssi?: string; csi?: string; cei?: string; nps?: string };
};

const Mean = ({ data }: MeanType) => {
  return (
    <MeanContainer>
      <div className="mean-title">{data?.definition_text}</div>
      {(data?.ssi || data?.csi) && (
        <div className="row-color" style={{ backgroundColor: 'rgba(29, 121, 255, 0.3)' }}>
          {data?.ssi || data?.csi}
        </div>
      )}
      {data?.cei && (
        <div className="row-color" style={{ backgroundColor: 'rgba(0, 161, 162, 0.3)' }}>
          {data?.cei}
        </div>
      )}
      {data?.nps && (
        <div className="row-color" style={{ backgroundColor: 'rgba(240, 100, 0, 0.3)' }}>
          {data?.nps}
        </div>
      )}
    </MeanContainer>
  );
};

export default Mean;
