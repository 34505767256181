import styled from '@emotion/styled/macro';

type CompareTableType = {
  colors?: string[];
};

export const CompareTableContainer = styled.div<CompareTableType>`
  table {
    width: 100%;
  }

  tr {
    display: flex;
  }

  th {
    font-size: 1.1rem;
    border-radius: 10px;
    margin-bottom: 20px;
  }

  th,
  td {
    flex: 1;
    padding: 20px 0;
  }

  td {
    padding: 20px;
  }

  th:nth-child(1),
  td:nth-child(1) {
    width: 100px;
    flex: unset;
    font-weight: 500;
    font-size: 1.1rem;
  }

  th:nth-child(2),
  td:nth-child(2) {
    margin-right: 20px;
  }

  th:nth-child(3),
  td:nth-child(3) {
  }

  th:nth-child(2) {
    background-color: ${({ colors }) => colors?.[0] && `rgba(${colors?.[0]},0.2)`};
    color: ${({ colors }) => colors?.[0] && `rgba(${colors?.[0]})`};
  }

  th:nth-child(3) {
    background-color: ${({ colors }) => colors?.[1] && `rgba(${colors?.[1]},0.2)`};
    color: ${({ colors }) => colors?.[1] && `rgba(${colors?.[1]})`};
  }

  tr:nth-child(odd) {
    td:nth-child(2) {
      background-color: ${({ colors }) => colors?.[0] && `rgba(${colors?.[0]},0.6)`};
    }

    td:nth-child(3) {
      background-color: ${({ colors }) => colors?.[1] && `rgba(${colors?.[1]},0.6)`};
    }
  }

  tr:nth-child(even) {
    td:nth-child(2) {
      background-color: #f2f2f2;
    }

    td:nth-child(3) {
      background-color: #f2f2f2;
    }
  }

  tr:first-child {
    td:nth-child(2) {
      border-radius: 8px 8px 0 0;
    }

    td:nth-child(3) {
      border-radius: 8px 8px 0 0;
    }
  }

  tr:last-child {
    td:nth-child(2) {
      border-radius: 0 0 8px 8px;
    }

    td:nth-child(3) {
      border-radius: 0 0 8px 8px;
    }
  }

  .content {
    display: flex;
    justify-content: space-between;

    .topic {
      color: currentColor;
      margin-right: 10px;
    }

    .point {
      font-size: 1.2rem;
      font-weight: 600;
      color: rgba(63, 63, 70, 1);
    }
  }
`;
