import styled from '@emotion/styled/macro';

export const SSIScoreContainer = styled.div`
  .mixed-chart {
    height: 450px;
    margin-left: 100px;
  }

  .mixed-chart-table {
    margin-right: 30px;

    .ant-table-thead > tr > th {
      background-color: #cccccc;
    }

    .ant-table-tbody > tr:nth-child(odd) > td {
      background-color: #e6e6e6;
    }

    .ant-table-tbody > tr:nth-child(even) > td {
      background-color: #f2f2f2;
    }

    .ant-table-container table > thead > tr:first-child th:first-child {
      border-top-left-radius: 10px;
    }

    .ant-table-container table > thead > tr:first-child th:last-child {
      border-top-right-radius: 10px;
    }

    .ant-table-tbody > tr:last-child > td:first-child {
      border-bottom-left-radius: 10px;
    }

    .ant-table-tbody > tr:last-child > td:last-child {
      border-bottom-right-radius: 10px;
    }
  }

  .line-chart {
    height: 450px;
  }

  .flex {
    display: flex;
  }

  .sb {
    justify-content: space-between;
  }

  .custom-legend .legend {
    align-items: center;
  }
`;
