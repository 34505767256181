import * as Common from 'components/common';
import BoxBorder from 'components/boxBorder';
import Indicators from 'components/indicators';
import Mean from 'components/mean';
import DonutChart from 'components/donutChart';
import { renderColors } from 'utils/renderColors';

import { OverallContainer } from './style';
import Note from 'components/note';
import { useAppSelector } from 'store';

const Overall = ({ data }: any) => {
  const boxBorderColor = ['#1D79FF', '#00A1A2', '#F06400'];
  const filter = useAppSelector((state) => state.filter);
  const scores = data?.overview_score?.scores ?? undefined;
  const colorCode = data?.overview_score?.color_code ?? undefined;
  const definition = data?.overview_score?.definition ?? undefined;

  return (
    <>
      {data.show && (
        <OverallContainer>
          <Common.TitleSection>{data?.header_text ?? ''}</Common.TitleSection>
          <Common.BoxContainer>
            <div className="grid-3">
              {scores &&
                scores.map?.((score: any, idx: number) => (
                  <BoxBorder key={`overview-${idx}`} title={score.section_text} borderColor={boxBorderColor[idx] ?? ''}>
                    <Common.SubTitleInCard style={{ fontWeight: 300 }}>
                      ({score.maximum_text ?? ''})
                    </Common.SubTitleInCard>
                    <Common.SubTitleInCard style={{ textAlign: 'left', display: 'inline-block' }}>
                      {`n = ${score?.n ?? ''}`}
                    </Common.SubTitleInCard>
                    <Common.SubTitleInCard style={{ float: 'right' }}>{score.target_text ?? ''}</Common.SubTitleInCard>
                    <br />
                    <div className="donut-chart">
                      <DonutChart
                        series={[score.score, score.total - score.score]}
                        colors={[renderColors[score?.color], '#CCCCCC']}
                        isPercent={idx > 0}
                      />
                    </div>
                    {idx == 0 &&
                      ((+filter.yearSelected == 2023 && +filter.monthSelected >= 4) || +filter.yearSelected > 2023) &&
                      (filter.languageSelected == 'TH' ? (
                        <Note data={['*แสดงข้อมูลตั้งแต่ เมษายน 2023']} />
                      ) : (
                        <Note data={["*Display data from Apr'23 Onwards"]} />
                      ))}
                  </BoxBorder>
                ))}
            </div>
            <br />
            <br />
            <div className="grid-2">
              <div>
                {colorCode && (
                  <Indicators
                    data={[
                      {
                        target_text: colorCode[0]?.color ?? '',
                        target_text_color: '#00A1A2',
                        ssi: colorCode[0]?.ssi ?? '',
                        cei: colorCode[0]?.cei ?? '',
                        nps: colorCode[0]?.nps ?? '',
                      },
                      {
                        target_text: colorCode[1]?.color ?? '',
                        target_text_color: '#FCD447',
                        ssi: colorCode[1]?.ssi ?? '',
                        cei: colorCode[1]?.cei ?? '',
                        nps: colorCode[1]?.nps ?? '',
                      },
                      {
                        target_text: colorCode[2]?.color ?? '',
                        target_text_color: '#D20043',
                        ssi: colorCode[2]?.ssi ?? '',
                        cei: colorCode[2]?.cei ?? '',
                        nps: colorCode[2]?.nps ?? '',
                      },
                    ]}
                    headers={['Color', 'SSI Score', 'CEI Score', 'NPS']}
                  />
                )}
              </div>
              <div>
                <Mean
                  data={{
                    definition_text: definition?.definition_text ?? '',
                    ssi: definition?.ssi ?? '',
                    cei: definition?.cei ?? '',
                    nps: definition?.nps ?? '',
                  }}
                />
              </div>
            </div>
          </Common.BoxContainer>
        </OverallContainer>
      )}
    </>
  );
};

export default Overall;
