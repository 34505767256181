import { LegendsContainer } from './style';

type LegendsType = {
  vertical?: boolean;
  legends: any[];
  colors?: string[];
  style?: React.CSSProperties;
};

const Legends = ({ vertical = false, legends, colors, style }: LegendsType) => {
  return (
    <LegendsContainer vertical={vertical} style={style}>
      {legends?.map?.((legend, index) => {
        return (
          <>
            {legend && (
              <div className="legend" key={legend?.name}>
                {legend?.type != 'line' ? (
                  <div className="marker" style={{ backgroundColor: colors?.[index] }} />
                ) : (
                  <div className="line-marker" style={{ color: '#0136E0' }} />
                )}
                <div className="legend-name">
                  {legend?.name}
                  {legend?.n && (
                    <>
                      <br />({legend?.n})
                    </>
                  )}
                </div>
              </div>
            )}
          </>
        );
      })}
    </LegendsContainer>
  );
};

export default Legends;
