import Nav from './nav';
import Sidebar from './sidebar';
import { Spin } from 'antd';
import { NavContainer, SidebarContainer, ContentContainer } from './style';
import { useAppSelector } from 'store';
import { useState, useEffect } from 'react';

const MainLayout = ({ children }: { children: any }) => {
  const [loading, setLoading] = useState(false);
  const summaryLoading = useAppSelector((state) => state.summary.loading);
  const ssiLoading = useAppSelector((state) => state.ssi.loading);
  const csiLoading = useAppSelector((state) => state.csi.loading);
  const csiCornerLoading = useAppSelector((state) => state.csiCorner.loading);

  useEffect(() => {
    if (summaryLoading || ssiLoading || csiLoading || csiCornerLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [summaryLoading, ssiLoading, csiLoading, csiCornerLoading]);

  return (
    <>
      <SidebarContainer>
        <Sidebar />
      </SidebarContainer>
      <NavContainer>
        <Nav />
      </NavContainer>
      <ContentContainer>
        <Spin spinning={loading} size="large">
          {children}
        </Spin>
      </ContentContainer>
    </>
  );
};

export default MainLayout;
