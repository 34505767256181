import styled from '@emotion/styled/macro';

export const LegendsContainer = styled.div<{ vertical?: boolean }>`
  margin: 20px 0;
  display: flex;
  flex-direction: ${({ vertical }) => (vertical ? 'column' : 'row')};

  .legend:not(:last-of-type) {
    margin-right: ${({ vertical }) => (vertical ? '0' : '15px')};
    margin-bottom: ${({ vertical }) => (vertical ? '10px' : '0')};
  }

  .legend {
    display: flex;

    .marker {
      width: 12px;
      height: 12px;
      min-width: 12px;
      min-height: 12px;
      margin-top: 2px;
      margin-right: 5px;
      border-radius: 50%;
      background-color: #333;
    }

    .line-marker {
      width: 20px;
      height: 1px;
      border-top: 2px dashed;
      margin-right: 5px;
    }

    .legend-name {
      color: var(--color-primary);
    }
  }
`;
