import { BoxBorderContainer } from './style';

type PropsType = {
  title: string;
  borderColor?: string;
  style?: React.CSSProperties;
};

const BoxBorder: React.FC<PropsType> = ({ children, borderColor, title, style }) => {
  return (
    <BoxBorderContainer style={{ ...{ borderColor: borderColor }, ...style }}>
      <div className="title-border">{title}</div>
      {children}
    </BoxBorderContainer>
  );
};

export default BoxBorder;
