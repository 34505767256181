import api from './api';

export const loginApi = (username: string, password: string) => {
  return api.post('/v1/auth/login', {
    username,
    password,
  });
};

export const authApi = () => {
  return api.get('/v1/auth');
};
