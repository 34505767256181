import styled from '@emotion/styled/macro';

export const TableContainer = styled.div`
  overflow: hidden;

  .ant-table-thead > tr > th {
    background-color: #cccccc;
  }

  .ant-table-tbody > tr:nth-child(odd) > td {
    background-color: #e6e6e6;
  }

  .ant-table-tbody > tr:nth-child(even) > td {
    background-color: #f2f2f2;
  }

  .ant-table-tbody > tr:first-child td:first-child {
    border-top-left-radius: 10px;
  }

  .ant-table-tbody > tr:first-child td:last-child {
    border-top-right-radius: 10px;
  }

  .ant-table-tbody > tr:last-child > td:first-child {
    border-bottom-left-radius: 10px;
  }

  .ant-table-tbody > tr:last-child > td:last-child {
    border-bottom-right-radius: 10px;
  }

  .ant-table-tbody > tr > td:nth-child(2) {
    padding: 0;
    position: unset;
  }

  .chart-in-table {
    position: absolute;
    top: 0;
    right: 0;
    width: calc(100% - 600px);
    height: 100%;
  }
`;
