import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { retrieveFilterApi } from 'services/filter';

export const retrieveFilter = createAsyncThunk('filter/retrieveFilter', async (payload: any) => {
  const response = await retrieveFilterApi(payload);

  return response.data;
});

interface FilterState {
  language: string[];
  year: string[];
  halfYear: string[];
  quarter: string[];
  month: string[];
  period: string[];
  dealer: string[];
  bubbleSsi: any[];
  bubbleCsi: any[];
  bubbleCor: any[];
  ssiBubbleS1: any[];
  ssiBubbleS2: any[];
  ssiBubbleS3: any[];
  ssiBubbleS4: any[];
  csiBubbleS1: any[];
  csiBubbleS2: any[];
  csiBubbleS3: any[];
  csiBubbleS4: any[];
  corBubbleS1: any[];
  corBubbleS2: any[];
  corBubbleS3: any[];
  corBubbleS4: any[];
  languageSelected: string;
  yearSelected: string;
  halfYearSelected: string;
  quarterSelected: string;
  monthSelected: string;
  periodSelected: string;
  dealerSelected: string;
  bubbleSsiSelected?: string;
  bubbleCsiSelected?: string;
  bubbleCorSelected?: string;
  ssiBubbleS1Selected?: string;
  ssiBubbleS2Selected?: string;
  ssiBubbleS3Selected?: string;
  ssiBubbleS4Selected?: string;
  csiBubbleS1Selected?: string;
  csiBubbleS2Selected?: string;
  csiBubbleS3Selected?: string;
  csiBubbleS4Selected?: string;
  corBubbleS1Selected?: string;
  corBubbleS2Selected?: string;
  corBubbleS3Selected?: string;
  corBubbleS4Selected?: string;
  fetchFilterState: boolean;
}

const initialState: FilterState = {
  language: [],
  year: [],
  halfYear: [],
  quarter: [],
  month: [],
  period: [],
  dealer: [],
  bubbleSsi: [],
  bubbleCsi: [],
  bubbleCor: [],
  ssiBubbleS1: [],
  ssiBubbleS2: [],
  ssiBubbleS3: [],
  ssiBubbleS4: [],
  csiBubbleS1: [],
  csiBubbleS2: [],
  csiBubbleS3: [],
  csiBubbleS4: [],
  corBubbleS1: [],
  corBubbleS2: [],
  corBubbleS3: [],
  corBubbleS4: [],
  languageSelected: 'TH',
  yearSelected: '',
  halfYearSelected: '',
  quarterSelected: '',
  monthSelected: '',
  periodSelected: '',
  dealerSelected: '',
  bubbleSsiSelected: undefined,
  bubbleCsiSelected: undefined,
  bubbleCorSelected: undefined,
  ssiBubbleS1Selected: undefined,
  ssiBubbleS2Selected: undefined,
  ssiBubbleS3Selected: undefined,
  ssiBubbleS4Selected: undefined,
  csiBubbleS1Selected: undefined,
  csiBubbleS2Selected: undefined,
  csiBubbleS3Selected: undefined,
  csiBubbleS4Selected: undefined,
  corBubbleS1Selected: undefined,
  corBubbleS2Selected: undefined,
  corBubbleS3Selected: undefined,
  corBubbleS4Selected: undefined,
  fetchFilterState: false,
};

export const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    languageSelected: (state, { payload }) => {
      state.languageSelected = payload;
    },
    yearSelected: (state, { payload }) => {
      state.yearSelected = payload;
    },
    halfYearSelected: (state, { payload }) => {
      state.halfYearSelected = payload;
    },
    quarterSelected: (state, { payload }) => {
      state.quarterSelected = payload;
    },
    monthSelected: (state, { payload }) => {
      state.monthSelected = payload;
    },
    periodSelected: (state, { payload }) => {
      state.periodSelected = payload;
    },
    dealerSelected: (state, { payload }) => {
      state.dealerSelected = payload;
    },
    bubbleSsiSelected: (state, { payload }) => {
      state.bubbleSsiSelected = payload;
    },
    bubbleCsiSelected: (state, { payload }) => {
      state.bubbleCsiSelected = payload;
    },
    bubbleCorSelected: (state, { payload }) => {
      state.bubbleCorSelected = payload;
    },
    ssiBubbleS1Selected: (state, { payload }) => {
      state.ssiBubbleS1Selected = payload;
    },
    ssiBubbleS2Selected: (state, { payload }) => {
      state.ssiBubbleS2Selected = payload;
    },
    ssiBubbleS3Selected: (state, { payload }) => {
      state.ssiBubbleS3Selected = payload;
    },
    ssiBubbleS4Selected: (state, { payload }) => {
      state.ssiBubbleS4Selected = payload;
    },
    csiBubbleS1Selected: (state, { payload }) => {
      state.csiBubbleS1Selected = payload;
    },
    csiBubbleS2Selected: (state, { payload }) => {
      state.csiBubbleS2Selected = payload;
    },
    csiBubbleS3Selected: (state, { payload }) => {
      state.csiBubbleS3Selected = payload;
    },
    csiBubbleS4Selected: (state, { payload }) => {
      state.csiBubbleS4Selected = payload;
    },
    corBubbleS1Selected: (state, { payload }) => {
      state.corBubbleS1Selected = payload;
    },
    corBubbleS2Selected: (state, { payload }) => {
      state.corBubbleS2Selected = payload;
    },
    corBubbleS3Selected: (state, { payload }) => {
      state.corBubbleS3Selected = payload;
    },
    corBubbleS4Selected: (state, { payload }) => {
      state.corBubbleS4Selected = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(retrieveFilter.fulfilled, (state, { payload }: PayloadAction<any>) => {
      const languageSelected = payload.language.filter((value: any) => value.default === true);
      const yearSelected = payload.year.filter((value: any) => value.default === true);
      const halfYearSelected = payload.halfYear.filter((value: any) => value.default === true);
      const quarterSelected = payload.quarter.filter((value: any) => value.default === true);
      const monthSelected = payload.month.filter((value: any) => value.default === true);
      const periodSelected = payload.period.filter((value: any) => value.default === true);
      const dealerSelected = payload.dealer.filter((value: any) => value.default === true);

      state.languageSelected = languageSelected[0]?.id ?? '';
      if (state.yearSelected == '') {
        state.yearSelected = yearSelected[0]?.id ?? '';
      }
      state.halfYearSelected = halfYearSelected[0]?.id ?? '';
      state.quarterSelected = quarterSelected[0]?.id ?? '';
      if (state.monthSelected == '') {
        state.monthSelected = monthSelected[0]?.id ?? '';
      }
      state.periodSelected = periodSelected[0]?.id ?? '';
      if (dealerSelected[0]?.id) {
        state.dealerSelected = dealerSelected[0]?.id ?? '';
      }

      state.language = payload.language;
      state.year = payload.year;
      state.halfYear = payload.halfYear;
      state.quarter = payload.quarter;
      state.month = payload.month;
      state.period = payload.period;
      state.dealer = payload.dealer;
      state.bubbleSsi = payload.buble_ssi;
      state.bubbleCsi = payload.buble_csi;
      state.bubbleCor = payload.buble_cor;
      state.ssiBubbleS1 = payload.ssi_buble_s1_data;
      state.ssiBubbleS2 = payload.ssi_buble_s2_data;
      state.ssiBubbleS3 = payload.ssi_buble_s3_data;
      state.ssiBubbleS4 = payload.ssi_buble_s4_data;
      state.csiBubbleS1 = payload.csi_buble_s1_data;
      state.csiBubbleS2 = payload.csi_buble_s2_data;
      state.csiBubbleS3 = payload.csi_buble_s3_data;
      state.csiBubbleS4 = payload.csi_buble_s4_data;
      state.corBubbleS1 = payload.cor_buble_s1_data;
      state.corBubbleS2 = payload.cor_buble_s2_data;
      state.corBubbleS3 = payload.cor_buble_s3_data;
      state.corBubbleS4 = payload.cor_buble_s4_data;
      state.fetchFilterState = true;
    });
  },
});

export const {
  languageSelected,
  yearSelected,
  halfYearSelected,
  quarterSelected,
  monthSelected,
  periodSelected,
  dealerSelected,
  bubbleSsiSelected,
  bubbleCsiSelected,
  bubbleCorSelected,
  ssiBubbleS1Selected,
  ssiBubbleS2Selected,
  ssiBubbleS3Selected,
  ssiBubbleS4Selected,
  csiBubbleS1Selected,
  csiBubbleS2Selected,
  csiBubbleS3Selected,
  csiBubbleS4Selected,
  corBubbleS1Selected,
  corBubbleS2Selected,
  corBubbleS3Selected,
  corBubbleS4Selected,
} = filterSlice.actions;

export default filterSlice.reducer;
