import styled from '@emotion/styled';

export const SidebarContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  padding: 15px;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  img[alt='logo'] {
    width: 100px;
    margin: 0 auto;
    padding: 20px 0 35px 0;
  }

  .header-group {
    cursor: pointer;
    font-size: 1.04rem;
    font-weight: 400;
    margin-bottom: 20px;
  }

  .expand-box {
    box-sizing: border-box;
    max-height: 0px;
    overflow: hidden;
    transition: 0.2s;
  }

  .expand-box.open {
    max-height: 380px;
  }

  .menu {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 1.04rem;
    font-weight: 400;
    margin-bottom: 5px;
    padding: 8px 12px;
    color: var(--secondary-color);
  }

  .menu:last-child {
    margin-bottom: 20px;
  }

  .menu:hover {
    color: var(--primary-color);
    background-color: rgba(228, 231, 231, 1);
    border-radius: 10px;
  }

  .menu.active {
    color: var(--primary-color);
    background-color: rgba(228, 231, 231, 1);
    border-radius: 10px;
  }

  .logout {
    cursor: pointer;
    margin-top: auto;
    margin-bottom: 20px;
    padding-left: 0px;
    font-weight: 500;
  }

  .logout:hover {
    color: var(--red);
  }
`;
