import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getCsiCornerApi } from 'services/csiCorner';
import { logout } from './authSlice';

export const getCsiCorner = createAsyncThunk('csiCorner/getCsiCorner', async (filter: any, thunk: any) => {
  try {
    const response = await getCsiCornerApi(filter);

    return response.data;
  } catch (error: any) {
    if (error.response.status === 401) {
      thunk.dispatch(logout());
    }
  }
});

interface CsiCornerState {
  data: any;
  error: boolean;
  loading: boolean;
}

const initialState: CsiCornerState = {
  data: {},
  error: false,
  loading: false,
};

export const csiCornerSlice = createSlice({
  name: 'csiCorner',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCsiCorner.fulfilled, (state, action: PayloadAction<any>) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(getCsiCorner.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCsiCorner.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default csiCornerSlice.reducer;
