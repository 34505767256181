import Chart from 'react-apexcharts';

type ColumnChartType = {
  colors?: string[];
  data: any[];
  series: { name: string; data: any[] }[];
};

const BarChart = ({ data, series, colors }: ColumnChartType) => {
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 5,
        dataLabels: {
          position: 'top',
          hideOverflowingLabels: false,
          orientation: 'horizontal' as any,
        },
      },
    },
    dataLabels: {
      style: {
        colors: ['#585858'],
      },
      offsetX: 27,
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    xaxis: {
      categories: data?.map?.((bar) => bar?.name) || [],
      max: data?.[0].total + 100,
      labels: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        maxWidth: 270,
        style: {
          colors: [],
          fontSize: '14px',
          fontFamily: 'Prompt',
          fontWeight: 500,
        },
      },
    },
    annotations: {
      xaxis: [
        {
          x: data?.[0]?.target,
          borderColor: '#000',
          label: {
            borderColor: '#000',
            style: {
              color: '#fff',
              background: '#000',
            },
            text: `Target ${data?.[0].target}`,
          },
        },
      ],
    },
    legend: {
      show: false,
    },
    colors: colors,
    states: {
      hover: {
        filter: {
          value: 0,
        },
      },
      active: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
  };

  return <Chart options={options} series={series} type="bar" height="100%" />;
};

export default BarChart;
