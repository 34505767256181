import Chart from 'react-apexcharts';

type ColumnChartType = {
  colors?: string[];
  data: { name?: any; score?: any; n?: any; total?: any }[];
};

const ColumnChart = ({ colors, data }: ColumnChartType) => {
  const options = {
    chart: {
      fontFamily: 'Prompt, Helvetica, Arial, sans-serif',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        dataLabels: {
          position: 'top', // top, center, bottom
        },
        distributed: true,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: any) {
        if (data?.[0]?.total == 100) {
          return val + ' %';
        }
        return val + ' PTs';
      },
      offsetY: -25,
      style: {
        fontSize: data?.length > 5 ? '11px' : '13.5px',
        colors: ['#374151'],
      },
    },
    xaxis: {
      categories: data?.map?.((column) => [[column?.name], [column?.n]]) || [],
      labels: {
        rotate: -60,
        trim: data?.length > 5 ? true : false,
        formatter: function (value: any, timestamp: any, opts: any) {
          return value;
        },
        style: {
          colors: [],
          fontSize: '12px',
          fontFamily: 'Prompt',
        },
      },
    },
    yaxis: {
      show: false,
      tickAmount: 1,
      max: data?.[0]?.total,
    },
    annotations: {
      yaxis: [
        {
          y: 0,
        },
      ],
    },
    legend: {
      show: false,
    },
    colors: data?.map?.((column, index) => {
      if (index == 0) {
        return colors?.[0] ?? '#ddd';
      }
      return colors?.[1] ?? '#ddd';
    }),
    states: {
      hover: {
        filter: {
          value: 0,
        },
      },
      active: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
  };
  const series = [
    {
      name: 'score',
      data: data?.map?.((column) => column?.score) || [],
    },
  ];

  return <Chart options={options} series={series} type="bar" height="100%" />;
};

export default ColumnChart;
