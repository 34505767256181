import * as Common from 'components/common';
import BoxBorder from 'components/boxBorder';
import DonutChart from 'components/donutChart';
import Indicators from 'components/indicators';
import Mean from 'components/mean';
import Legends from 'components/legends';
import Note from 'components/note';
import BubbleChart from 'components/bubbleChart';
import { Select } from 'antd';
import { EngagementContainer } from './style';
import { useEffect, useState } from 'react';
import { renderColors } from 'utils/renderColors';
import { useAppDispatch, useAppSelector } from 'store';
import { corBubbleS2Selected } from 'store/filterSlice';

const Engagement = ({ data }: any) => {
  const dispatch = useAppDispatch();
  const filter = useAppSelector((state) => state.filter);
  const [ceiScore, setCeiScore] = useState<any>({});
  const [allScores, setAllScores] = useState<any>([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    const engagement = data?.corner_engagement_1_2 ?? {};

    const allScoresList: any = [];
    const say = engagement?.say_score ?? {};
    const stay = engagement?.stay_score ?? {};
    const strive = engagement?.strive_score ?? {};
    allScoresList.push({
      header: say?.header_text ?? '',
      legends: say?.legend ?? [],
      title1: say?.categories?.[0]?.name ?? '',
      title2: say?.categories?.[1]?.name ?? '',
      title3: say?.categories?.[2]?.name ?? '',
      n1: say?.categories?.[0]?.n ?? '',
      n2: say?.categories?.[1]?.n ?? '',
      n3: say?.categories?.[2]?.n ?? '',
      data1: [
        say?.scores?.[0]?.score1 ?? 0,
        say?.scores?.[0]?.score2 ?? 0,
        say?.scores?.[0]?.score3 ?? 0,
        say?.scores?.[0]?.score4 ?? 0,
        say?.scores?.[0]?.score5 ?? 0,
      ],
      data2: [
        say?.scores?.[1]?.score1 ?? 0,
        say?.scores?.[1]?.score2 ?? 0,
        say?.scores?.[1]?.score3 ?? 0,
        say?.scores?.[1]?.score4 ?? 0,
        say?.scores?.[1]?.score5 ?? 0,
      ],
      data3: [
        say?.scores?.[2]?.score1 ?? 0,
        say?.scores?.[2]?.score2 ?? 0,
        say?.scores?.[2]?.score3 ?? 0,
        say?.scores?.[2]?.score4 ?? 0,
        say?.scores?.[2]?.score5 ?? 0,
      ],
      notes: [say?.base_text ?? '', say?.ref_text ?? ''],
      colors: ['#1D79FF', '#00A1A2', '#FCD447', '#F06400', '#D20043'],
    });
    allScoresList.push({
      header: stay?.header_text ?? '',
      legends: stay?.legend ?? [],
      title1: stay?.categories?.[0]?.name ?? '',
      title2: stay?.categories?.[1]?.name ?? '',
      title3: stay?.categories?.[2]?.name ?? '',
      n1: stay?.categories?.[0]?.n ?? '',
      n2: stay?.categories?.[1]?.n ?? '',
      n3: stay?.categories?.[2]?.n ?? '',
      data1: [
        stay?.scores?.[0]?.score1 ?? 0,
        stay?.scores?.[0]?.score2 ?? 0,
        stay?.scores?.[0]?.score3 ?? 0,
        stay?.scores?.[0]?.score4 ?? 0,
        stay?.scores?.[0]?.score5 ?? 0,
      ],
      data2: [
        stay?.scores?.[1]?.score1 ?? 0,
        stay?.scores?.[1]?.score2 ?? 0,
        stay?.scores?.[1]?.score3 ?? 0,
        stay?.scores?.[1]?.score4 ?? 0,
        stay?.scores?.[1]?.score5 ?? 0,
      ],
      data3: [
        stay?.scores?.[2]?.score1 ?? 0,
        stay?.scores?.[2]?.score2 ?? 0,
        stay?.scores?.[2]?.score3 ?? 0,
        stay?.scores?.[2]?.score4 ?? 0,
        stay?.scores?.[2]?.score5 ?? 0,
      ],
      notes: [stay?.base_text ?? '', stay?.ref_text ?? ''],
      colors: ['#1D79FF', '#00A1A2', '#FCD447', '#F06400', '#D20043'],
    });
    allScoresList.push({
      header: strive?.header_text ?? '',
      legends: strive?.legend ?? [],
      title1: strive?.categories?.[0]?.name ?? '',
      title2: strive?.categories?.[1]?.name ?? '',
      title3: strive?.categories?.[2]?.name ?? '',
      n1: strive?.categories?.[0]?.n ?? '',
      n2: strive?.categories?.[1]?.n ?? '',
      n3: strive?.categories?.[2]?.n ?? '',
      data1: [
        strive?.scores?.[0]?.score1 ?? 0,
        strive?.scores?.[0]?.score2 ?? 0,
        strive?.scores?.[0]?.score3 ?? 0,
        strive?.scores?.[0]?.score4 ?? 0,
        strive?.scores?.[0]?.score5 ?? 0,
      ],
      data2: [
        strive?.scores?.[1]?.score1 ?? 0,
        strive?.scores?.[1]?.score2 ?? 0,
        strive?.scores?.[1]?.score3 ?? 0,
        strive?.scores?.[1]?.score4 ?? 0,
        strive?.scores?.[1]?.score5 ?? 0,
      ],
      data3: [
        strive?.scores?.[2]?.score1 ?? 0,
        strive?.scores?.[2]?.score2 ?? 0,
        strive?.scores?.[2]?.score3 ?? 0,
        strive?.scores?.[2]?.score4 ?? 0,
        strive?.scores?.[2]?.score5 ?? 0,
      ],
      notes: [strive?.base_text ?? '', strive?.ref_text ?? ''],
      colors: ['#1D79FF', '#00A1A2', '#FCD447', '#F06400', '#D20043'],
    });

    setShow(engagement?.show ?? false);
    setCeiScore(engagement?.pie_chart ?? {});
    setAllScores(allScoresList);
  }, [data]);

  return (
    <>
      {show && (
        <EngagementContainer>
          <Common.TitleSection>Engagement</Common.TitleSection>
          <Common.BoxContainer>
            <div className="flex">
              <div className="flex" style={{ marginRight: '20px', flex: '2' }}>
                <BoxBorder title="CEI Score" borderColor="#00A1A2" style={{ width: '100%' }}>
                  <div className="flex">
                    <div style={{ marginRight: '20px', flex: '1' }}>
                      <div className="flex">
                        <Common.SubTitleInCard style={{ fontWeight: 300 }}>
                          {ceiScore?.scores?.[0]?.maximum_text ?? ''}
                        </Common.SubTitleInCard>
                        <Common.SubTitleInCard style={{ marginLeft: 'auto' }}>
                          {ceiScore?.scores?.[0]?.target_text ?? ''}
                        </Common.SubTitleInCard>
                      </div>
                      <Common.SubTitleInCard>{`n = ${ceiScore?.scores?.[0]?.n ?? ''}`}</Common.SubTitleInCard>
                      <br />
                      <div style={{ margin: '0 auto', maxWidth: '280px' }}>
                        <DonutChart
                          series={[
                            ceiScore?.scores?.[0]?.score ?? 0,
                            (ceiScore?.scores?.[0]?.total ?? 0) - (ceiScore?.scores?.[0]?.score ?? 0),
                          ]}
                          colors={[renderColors[ceiScore?.scores?.[0]?.color], '#CCCCCC']}
                          isPercent
                        />
                      </div>
                    </div>
                    <div style={{ flex: '1', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Indicators
                        style={{ display: 'flex', justifyContent: 'center' }}
                        headers={['สี', 'SSI Score']}
                        data={[
                          {
                            target_text: ceiScore?.color_code?.[0]?.color ?? '',
                            target_text_color: '#00A1A2',
                            cei: ceiScore?.color_code?.[0]?.cei ?? '',
                          },
                          {
                            target_text: ceiScore?.color_code?.[1]?.color ?? '',
                            target_text_color: '#FCD447',
                            cei: ceiScore?.color_code?.[1]?.cei ?? '',
                          },
                          {
                            target_text: ceiScore?.color_code?.[2]?.color ?? '',
                            target_text_color: '#D20043',
                            cei: ceiScore?.color_code?.[2]?.cei ?? '',
                          },
                        ]}
                      />
                    </div>
                  </div>
                </BoxBorder>
              </div>
              <div style={{ flex: '1', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Mean
                  data={{
                    definition_text: ceiScore?.definition?.definition_text ?? '',
                    cei: ceiScore?.definition?.cei ?? '',
                  }}
                />
              </div>
            </div>
          </Common.BoxContainer>
          <br />
          {allScores?.map?.((chart: any, index: number) => (
            <>
              <Common.BoxContainer>
                <div className="flex">
                  <div style={{ width: '100px' }}>
                    <Common.TitleInCard>{chart?.header}</Common.TitleInCard>
                    <div className="custom-legend">
                      <Legends legends={chart?.legends} colors={chart?.colors} vertical />
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <Common.TitleInCard style={{ textAlign: 'center' }}>{chart?.title1}</Common.TitleInCard>
                    <Common.SubTitleInCard style={{ textAlign: 'center', fontWeight: 300 }}>{`( n = ${
                      chart?.n1 ?? 'n/a'
                    } )`}</Common.SubTitleInCard>
                    <br />
                    <div style={{ padding: '0 10px', maxWidth: '300px', margin: '0 auto' }}>
                      <DonutChart series={chart?.data1} colors={chart?.colors} isPercent />
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <Common.TitleInCard style={{ textAlign: 'center' }}>{chart?.title2}</Common.TitleInCard>
                    <Common.SubTitleInCard style={{ textAlign: 'center', fontWeight: 300 }}>{`( n = ${
                      chart?.n2 ?? 'n/a'
                    } )`}</Common.SubTitleInCard>
                    <br />
                    <div style={{ padding: '0 10px', maxWidth: '300px', margin: '0 auto' }}>
                      <DonutChart series={chart?.data2} colors={chart?.colors} isPercent />
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <Common.TitleInCard style={{ textAlign: 'center' }}>{chart?.title3}</Common.TitleInCard>
                    <Common.SubTitleInCard style={{ textAlign: 'center', fontWeight: 300 }}>{`( n = ${
                      chart?.n3 ?? 'n/a'
                    } )`}</Common.SubTitleInCard>
                    <br />
                    <div style={{ padding: '0 10px', maxWidth: '300px', margin: '0 auto' }}>
                      <DonutChart series={chart?.data3} colors={chart?.colors} isPercent />
                    </div>
                  </div>
                </div>
                <br />
                <Note data={chart.notes} />
              </Common.BoxContainer>
              <br />
            </>
          ))}
          {data?.corner_bubble_engagement_2_2?.show && (
            <>
              <Common.BoxContainer>
                <Common.TitleInCard>{data?.corner_bubble_engagement_2_2?.header_text}</Common.TitleInCard>
                <br />
                <div className="filter-bubble-group">
                  <Select
                    value={filter.corBubbleS2Selected}
                    onChange={(value) => dispatch(corBubbleS2Selected(value))}
                    placeholder="Select"
                  >
                    {filter?.corBubbleS2?.map?.((data) => {
                      return (
                        <Select.Option key={data.id} value={data.id} disabled={!!+data.id}>
                          {data.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </div>
                <br />
                <div className="flex" style={{ width: '100%' }}>
                  {data?.corner_bubble_engagement_2_2?.bubble?.map?.((chart: any, index: number) => (
                    <div key={chart?.color + index} style={{ flex: '1', minWidth: '0' }}>
                      <div style={{ height: '650px', marginBottom: '10px', backgroundColor: chart?.bgColor }}>
                        <BubbleChart data={chart?.data} color={chart?.color} />
                      </div>
                      <div style={{ fontSize: '1.12rem', fontWeight: 500, textAlign: 'center' }}>
                        {chart?.section_text}
                      </div>
                    </div>
                  ))}
                </div>
              </Common.BoxContainer>
            </>
          )}
        </EngagementContainer>
      )}
    </>
  );
};

export default Engagement;
