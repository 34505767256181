import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { authApi, loginApi } from 'services/auth';

export const login = createAsyncThunk('auth/login', async (payload: any) => {
  const response = await loginApi(payload.username, payload.password);

  return response.data;
});

export const retrieveUser = createAsyncThunk('auth/retrieveUser', async (state: any, thunk: any) => {
  try {
    const response = await authApi();

    return response.data;
  } catch (error: any) {
    if (error.response.status === 401) {
      thunk.dispatch(logout());
    }
  }
});

interface AuthState {
  users: any;
  auth: boolean;
  error: boolean;
}

const users: any =
  localStorage.getItem('users') && localStorage.getItem('users') !== 'undefined'
    ? JSON.parse(localStorage.getItem('users') ?? '{}')
    : {} ?? {};
const initialState: AuthState = {
  users,
  auth: Object.keys(users).length > 0 ? true : false,
  error: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.users = {};
      state.auth = false;
      state.error = false;
      localStorage.removeItem('users');
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.auth = false;
      state.error = false;
    });
    builder.addCase(login.fulfilled, (state, action: PayloadAction<any>) => {
      localStorage.setItem('users', JSON.stringify({ authToken: action.payload.access_token }));
      state.users.authToken = action.payload.access_token;
      state.auth = true;
      state.error = false;
    });
    builder.addCase(login.rejected, (state) => {
      state.auth = false;
      state.error = true;
    });
    builder.addCase(retrieveUser.fulfilled, (state, action: PayloadAction<any>) => {
      const users = {
        ...action.payload.users,
        authToken: state.users.authToken,
      };

      localStorage.setItem('users', JSON.stringify(users));
      state.users = users;
      state.auth = true;
      state.error = false;
    });
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
