import styled from '@emotion/styled/macro';

export const BoxContainer = styled.div`
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
`;

export const TitleSection = styled.h2`
  font-size: 1.7rem;
  font-weight: 600;
  margin-bottom: 15px; ;
`;

export const TitleInCard = styled.h2`
  font-size: 1.3rem;
  font-weight: 500;
`;

export const SubTitleInCard = styled.h4`
  font-size: 1rem;
  font-weight: 400;
`;
