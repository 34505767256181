import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'store';
import { getCsi } from 'store/csiSlice';
import Overall from './overall';
import Score from './score';
import Engagement from './engagement';
import NPS from './nps';

const CSILexusDealers = () => {
  const dispatch = useAppDispatch();
  const filter = useAppSelector((state) => state.filter);
  const users = useSelector((state: any) => state.auth.users);
  const data = useSelector((state: any) => state.csi.data);

  useEffect(() => {
    if (filter.fetchFilterState && users.user_type) {
      dispatch(
        getCsi({
          user_type: users.user_type,
          languge: filter.languageSelected,
          year: filter.yearSelected,
          halfyear: filter.halfYearSelected,
          quarter: filter.quarterSelected,
          month: filter.monthSelected,
          period: filter.periodSelected,
          region: users.region_id ?? '',
          dealer: filter.dealerSelected,
          branch: users.branch_id ?? '',
          buble_csi_sub: filter?.csiBubbleS1Selected,
          buble_csi_cei: filter?.csiBubbleS2Selected,
          buble_csi_nps: filter?.csiBubbleS3Selected,
          buble_csi_improve: filter?.csiBubbleS4Selected,
        }),
      );
    }
  }, [filter]);

  return (
    <>
      <Overall data={data?.overview_section ?? {}} />
      <br />
      <Score data={data ?? {}} />
      <br />
      <Engagement data={data ?? {}} />
      <br />
      <NPS data={data ?? {}} />
    </>
  );
};

export default CSILexusDealers;
