import { ScatterChart, Scatter, XAxis, YAxis, ZAxis, LabelList, ResponsiveContainer } from 'recharts';

const BubbleChart = ({ color = '#ccc', data = [] }: any) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <ScatterChart
        margin={{
          top: 100,
          right: 100,
          bottom: 60,
          left: 100,
        }}
      >
        <XAxis
          type="number"
          dataKey="column"
          interval={0}
          tickLine={false}
          axisLine={false}
          tick={false}
          domain={['dataMin', 'dataMax']}
          allowDecimals={false}
        />
        <YAxis type="category" dataKey="row" tickLine={false} axisLine={false} tick={false} width={0} />
        <ZAxis type="number" dataKey="value" range={[10000, 20000]} name="score" />
        <Scatter data={[...data]?.reverse()} fill={color}>
          <LabelList dataKey="label" />
        </Scatter>
      </ScatterChart>
    </ResponsiveContainer>
  );
};

export default BubbleChart;
