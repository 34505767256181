import Router from './routers';
import { Global, css } from '@emotion/react';
import './antd.less';

function App() {
  return (
    <>
      <Global
        styles={css`
          html {
            font-family: sans-serif;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            text-rendering: optimizeLegibility;
          }

          body {
            margin: 0;
            font-family: 'Prompt', sans-serif;
            font-size: 13px;
            background-color: rgb(242, 242, 243);
            overflow: overlay;
          }

          :root {
            font-size: 13px;
            --app-color: rgba(74, 167, 171, 1);
            --app-code: 74, 167, 171;
            --primary-color: rgb(12, 13, 13);
            --primary-code: 12, 13, 13;
            --secondary-color: rgba(122, 132, 132);
            --secondary-code: 122, 132, 132;
            --red: #d20043;
          }

          ::-webkit-scrollbar {
            width: 10px;
            height: 10px;
          }

          /* Track */
          ::-webkit-scrollbar-track {
            background: transparent;
          }

          /* Handle */
          ::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 5px;
          }

          /* Handle on hover */
          ::-webkit-scrollbar-thumb:hover {
            background: #999;
          }

          h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          p {
            color: #0c0d0d;
            margin: 0;
          }

          .ant-select-item {
            font-size: 0.9rem;
          }

          .ant-select-clear {
            border-radius: 50%;
          }

          .title-modal {
            font-weight: 700 !important;
          }

          .sub-title-modal {
            font-weight: 300;
            font-size: 1.1rem;
            margin-bottom: 20px;
          }

          .apexcharts-legend.position-top.apexcharts-align-center,
          .apexcharts-legend.apx-legend-position-top.apexcharts-align-center {
            justify-content: flex-end !important;
          }

          .filter-bubble-group {
            display: flex;

            > * {
              min-width: 230px;
              margin-right: 20px;
            }
          }
        `}
      />
      <Router />
    </>
  );
}

export default App;
