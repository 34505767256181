import styled from '@emotion/styled/macro';

export const IndicatorsContainer = styled.div`
  table {
    border-radius: 10px;
    overflow: hidden;
  }

  thead {
    background-color: rgba(29, 121, 255, 0.5);
    color: #fff;
  }

  th,
  td {
    padding: 10px 20px;
    text-align: center;
    font-weight: 500;
  }

  tbody tr:nth-child(even) td:nth-child(n + 2) {
    filter: brightness(85%);
  }
`;
