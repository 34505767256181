import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getSummaryApi } from 'services/summary';
import { logout } from './authSlice';

export const getSummary = createAsyncThunk('summary/getSummary', async (filter: any, thunk: any) => {
  try {
    const response = await getSummaryApi(filter);

    return response.data;
  } catch (error: any) {
    if (error.response.status === 401) {
      thunk.dispatch(logout());
    }
  }
});
interface SummaryState {
  data: any;
  error: boolean;
  loading: boolean;
}

const initialState: SummaryState = {
  data: {},
  error: false,
  loading: false,
};

export const summarySlice = createSlice({
  name: 'summary',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSummary.fulfilled, (state, action: PayloadAction<any>) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(getSummary.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSummary.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default summarySlice.reducer;
