import Chart from 'react-apexcharts';

type PropsType = {
  labels: any[];
  series: { name: string; type: string; data: any[] }[];
  colors: string[];
  targetLine?: { name: string; color: string; value: number };
  reverseLegend?: boolean;
  strokeWidth?: number[];
};

const MixedChart = ({ labels, series, colors, targetLine, reverseLegend, strokeWidth }: PropsType) => {
  const options = {
    chart: {
      fontFamily: 'Prompt, Helvetica, Arial, sans-serif',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        dataLabels: {
          position: 'top', // top, center, bottom
        },
      },
    },
    stroke: {
      width: strokeWidth || [4, 0],
    },
    legend: {
      position: 'top' as const,
      onItemClick: {
        toggleDataSeries: false,
      },
      inverseOrder: !!reverseLegend,
    },
    dataLabels: {
      enabled: true,
    },
    labels: labels,
    yaxis: {
      min: function (min: any) {
        if (min == 0 || targetLine?.value == 0) {
          return min;
        }
        if ((targetLine?.value ?? 0) < min) {
          return (
            (targetLine?.value ?? 25) - ((targetLine?.value ?? 25) % 25 == 0 ? 25 : (targetLine?.value ?? 25) % 25)
          );
        }
        return (min ?? 25) - ((min ?? 25) % 25 == 0 ? 25 : (min ?? 25) % 25);
      },
      max: 1000,
      tickAmount: 5,
      labels: {
        style: {
          colors: [],
          fontSize: '14px',
        },
      },
    },
    xaxis: {
      labels: {
        style: {
          colors: [],
          fontSize: '14px',
        },
      },
    },
    colors: colors,
    annotations: {
      yaxis: [
        {
          y: targetLine?.value,
          strokeDashArray: 8,
          borderColor: targetLine?.color,
          label: {
            offsetX: -29,
            borderColor: targetLine?.color,
            style: {
              color: '#fff',
              background: targetLine?.color,
            },
            text: targetLine?.name,
          },
        },
      ],
    },
    states: {
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
  };

  return <Chart options={options} series={series} type="line" width="100%" height="100%" />;
};

export default MixedChart;
