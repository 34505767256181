import styled from '@emotion/styled';

export const SidebarContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 220px;
  z-index: 20;
`;

export const NavContainer = styled.div`
  position: fixed;
  left: 220px;
  top: 0;
  right: 0;
  z-index: 19;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 10px;
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #999;
  }
`;

export const ContentContainer = styled.div`
  position: absolute;
  left: 220px;
  right: 0px;
  padding: 20px;
  padding-top: 181px;
  min-width: 1000px;
  max-width: 1440px;
  margin: 0 auto;

  .ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
    position: fixed;
  }
`;
