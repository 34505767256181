import styled from '@emotion/styled/macro';

export const OverallContainer = styled.div`
  .grid-3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
  }

  .grid-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;

    > div {
      display: flex;
      align-items: center;
    }
  }

  .donut-chart {
    width: 70%;
    margin: 0 auto;
  }
`;
