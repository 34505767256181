import { useEffect, useState } from 'react';
import * as Common from 'components/common';
import BoxBorder from 'components/boxBorder';
import DonutChart from 'components/donutChart';
import Indicators from 'components/indicators';
import StackColumnChart from 'components/stackColumnChart';
import Legends from 'components/legends';
import Note from 'components/note';
import BubbleChart from 'components/bubbleChart';
import { renderColors } from 'utils/renderColors';
import { Select } from 'antd';
import { NPSContainer } from './style';
import { useAppDispatch, useAppSelector } from 'store';
import { csiBubbleS3Selected, csiBubbleS4Selected } from 'store/filterSlice';

const NPS = ({ data }: any) => {
  const dispatch = useAppDispatch();
  const filter = useAppSelector((state) => state.filter);
  const [allNps, setAllNps] = useState<any>({});
  const [dealerNps, setDealerNps] = useState<any>({});
  const [show, setShow] = useState(false);

  useEffect(() => {
    setAllNps(data?.csi_nps_1_2?.pie_chart ?? {});
    setDealerNps(data?.csi_nps_1_2?.bar_chart ?? {});
    setShow(data?.csi_nps_1_2?.show ?? false);
  }, [data]);

  return (
    <>
      {show && (
        <NPSContainer>
          <Common.TitleSection>NPS</Common.TitleSection>
          <Common.BoxContainer>
            <img
              style={{ display: 'block', margin: '0 auto', maxWidth: '890px' }}
              src="/images/nps.svg"
              alt="nps indicator"
              width="100%"
            />
          </Common.BoxContainer>
          <br />
          <Common.BoxContainer>
            <Common.TitleInCard>{allNps?.header_text ?? ''}</Common.TitleInCard>
            <br />
            <div className="flex">
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '20px' }}>
                <Indicators
                  headers={['สี', 'SSI Score']}
                  data={[
                    {
                      target_text: allNps?.color_code?.[0]?.color ?? '',
                      target_text_color: '#00A1A2',
                      nps: allNps?.color_code?.[0]?.nps ?? '',
                    },
                    {
                      target_text: allNps?.color_code?.[1]?.color ?? '',
                      target_text_color: '#FCD447',
                      nps: allNps?.color_code?.[1]?.nps ?? '',
                    },
                    {
                      target_text: allNps?.color_code?.[2]?.color ?? '',
                      target_text_color: '#D20043',
                      nps: allNps?.color_code?.[2]?.nps ?? '',
                    },
                  ]}
                />
              </div>
              <BoxBorder
                title={allNps?.scores?.[0]?.name ?? ''}
                borderColor="#F06400"
                style={{ flex: 1, marginRight: '20px' }}
              >
                <div className="flex">
                  <Common.SubTitleInCard style={{ fontWeight: 300, marginBottom: '5px' }}>
                    {allNps?.scores?.[0]?.maximum_text ?? ''}
                  </Common.SubTitleInCard>
                  <Common.SubTitleInCard style={{ marginLeft: 'auto' }}>
                    {allNps?.scores?.[0]?.target_text ?? ''}
                  </Common.SubTitleInCard>
                </div>
                <Common.SubTitleInCard style={{ fontWeight: 300 }}>
                  (N={allNps?.scores?.[0]?.n ?? 'N/A'})
                </Common.SubTitleInCard>
                <br />
                <div style={{ padding: '0 10px', maxWidth: '300px', margin: '0 auto' }}>
                  <DonutChart
                    series={[
                      allNps?.scores?.[0]?.score ?? 0,
                      (allNps?.scores?.[0]?.total ?? 0) - (allNps?.scores?.[0]?.score ?? 0),
                    ]}
                    colors={[renderColors[allNps?.scores?.[0]?.color], '#CCCCCC']}
                    isPercent
                  />
                </div>
              </BoxBorder>
              <BoxBorder
                title={allNps?.scores?.[1]?.name ?? ''}
                borderColor="#F06400"
                style={{ flex: 1, marginRight: '20px' }}
              >
                <div className="flex">
                  <Common.SubTitleInCard style={{ fontWeight: 300, marginBottom: '5px' }}>
                    {allNps?.scores?.[1]?.maximum_text ?? ''}
                  </Common.SubTitleInCard>
                  <Common.SubTitleInCard style={{ marginLeft: 'auto' }}>
                    {allNps?.scores?.[1]?.target_text ?? ''}
                  </Common.SubTitleInCard>
                </div>
                <Common.SubTitleInCard style={{ fontWeight: 300 }}>
                  (N={allNps?.scores?.[1]?.n ?? 'N/A'})
                </Common.SubTitleInCard>
                <br />
                <div style={{ padding: '0 10px', maxWidth: '300px', margin: '0 auto' }}>
                  <DonutChart
                    series={[
                      allNps?.scores?.[1]?.score ?? 0,
                      (allNps?.scores?.[1]?.total ?? 0) - (allNps?.scores?.[1]?.score ?? 0),
                    ]}
                    colors={[renderColors[allNps?.scores?.[1]?.color], '#CCCCCC']}
                    isPercent
                  />
                </div>
              </BoxBorder>
            </div>
          </Common.BoxContainer>
          <br />
          <Common.BoxContainer>
            <Common.TitleInCard>{dealerNps?.header_text ?? ''}</Common.TitleInCard>
            <Legends
              style={{ justifyContent: 'flex-end' }}
              legends={[{ name: 'Detracter' }, { name: 'Passive' }, { name: 'Promoter' }]}
              colors={['#D20043', '#F06400', '#00A1A2']}
            />
            <div className="stack-column-chart">
              <StackColumnChart
                data={[
                  {
                    name: 'Detracter',
                    score: [dealerNps?.scores?.[0]?.Detractor_score ?? 0, dealerNps?.scores?.[1]?.Detractor_score ?? 0],
                    total: 100,
                  },
                  {
                    name: 'Passive',
                    score: [dealerNps?.scores?.[0]?.Passive_score ?? 0, dealerNps?.scores?.[1]?.Passive_score ?? 0],
                    total: 100,
                  },
                  {
                    name: 'Promoter',
                    score: [dealerNps?.scores?.[0]?.Promoter_score ?? 0, dealerNps?.scores?.[1]?.Promoter_score ?? 0],
                    total: 100,
                  },
                ]}
                categories={[
                  [dealerNps?.scores?.[0]?.name ?? '', `(N=${dealerNps?.legend?.[0]?.n ?? 'N/A'})`],
                  [dealerNps?.scores?.[1]?.name ?? '', `(N=${dealerNps?.legend?.[1]?.n ?? 'N/A'})`],
                ]}
                colors={['#D20043', '#F06400', '#00A1A2']}
              />
            </div>
            <br />
            <Note data={[dealerNps?.base_text ?? '', dealerNps?.ref_text ?? '', dealerNps?.note ?? '']} />
          </Common.BoxContainer>
          <br />
          {data?.csi_bubble_nps_2_2?.show && (
            <>
              <Common.BoxContainer>
                <Common.TitleInCard>{data?.csi_bubble_nps_2_2?.header_text}</Common.TitleInCard>
                <br />
                <div className="filter-bubble-group">
                  <Select
                    value={filter.csiBubbleS3Selected}
                    onChange={(value) => dispatch(csiBubbleS3Selected(value))}
                    placeholder="Select"
                  >
                    {filter?.csiBubbleS3?.map?.((data) => {
                      return (
                        <Select.Option key={data.id} value={data.id} disabled={!!+data.id}>
                          {data.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </div>
                <br />
                <div className="flex" style={{ width: '100%' }}>
                  {data?.csi_bubble_nps_2_2?.bubble?.map?.((chart: any, index: number) => (
                    <div key={chart?.color + index} style={{ flex: '1', minWidth: '0' }}>
                      <div style={{ height: '650px', marginBottom: '10px', backgroundColor: chart?.bgColor }}>
                        <BubbleChart data={chart?.data} color={chart?.color} />
                      </div>
                      <div style={{ fontSize: '1.12rem', fontWeight: 500, textAlign: 'center' }}>
                        {chart?.section_text}
                      </div>
                    </div>
                  ))}
                </div>
              </Common.BoxContainer>
              <br />
            </>
          )}
          {data?.csi_bubble_overall?.show && (
            <>
              <Common.BoxContainer>
                <Common.TitleInCard>{data?.csi_bubble_overall?.header_text}</Common.TitleInCard>
                <br />
                <div className="filter-bubble-group">
                  <Select
                    value={filter.csiBubbleS4Selected}
                    onChange={(value) => dispatch(csiBubbleS4Selected(value))}
                    placeholder="Select"
                  >
                    {filter?.csiBubbleS4?.map?.((data) => {
                      return (
                        <Select.Option key={data.id} value={data.id} disabled={!!+data.id}>
                          {data.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </div>
                <br />
                <div className="flex" style={{ width: '100%' }}>
                  {data?.csi_bubble_overall?.bubble?.map?.((chart: any, index: number) => (
                    <div key={chart?.color + index} style={{ flex: '1', minWidth: '0' }}>
                      <div style={{ height: '650px', marginBottom: '10px', backgroundColor: chart?.bgColor }}>
                        <BubbleChart data={chart?.data} color={chart?.color} />
                      </div>
                      <div style={{ fontSize: '1.12rem', fontWeight: 500, textAlign: 'center' }}>
                        {chart?.section_text}
                      </div>
                    </div>
                  ))}
                </div>
              </Common.BoxContainer>
            </>
          )}
        </NPSContainer>
      )}
    </>
  );
};

export default NPS;
