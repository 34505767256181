import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SidebarContainer } from './style';
import { logout } from '../../store/authSlice';
import { useAppDispatch } from 'store';

const Sidebar = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const [openGroup, setOpenGroup] = useState('');

  const handleOpenExpand = (pathOfGroup: string[], groupName: string) => {
    return pathOfGroup.includes(location.pathname) || openGroup == groupName ? 'open' : '';
  };

  const toggleExpand = (groupName: string) => {
    if (openGroup === groupName) {
      setOpenGroup('');
    } else {
      setOpenGroup(groupName);
    }
  };

  const handleLogout = async () => {
    await dispatch(logout());
    history.push('/');
  };

  return (
    <SidebarContainer>
      <img src="/images/logo.png" alt="logo" />
      <div>
        <div
          className={`menu ${location.pathname === '/summary' ? 'active' : ''}`}
          onClick={() => history.push('/summary')}
        >
          ข้อมูลสรุป
        </div>
        <div className={`menu ${location.pathname === '/ssi' ? 'active' : ''}`} onClick={() => history.push('/ssi')}>
          SSI
        </div>
        <div
          className={`menu ${location.pathname === '/csi-lexus-dealers' ? 'active' : ''}`}
          onClick={() => history.push('/csi-lexus-dealers')}
        >
          CSI - Lexus Dealers
        </div>
        <div
          className={`menu ${location.pathname === '/csi-service-corner' ? 'active' : ''}`}
          onClick={() => history.push('/csi-service-corner')}
        >
          CSI - Service Corner
        </div>
      </div>
      <div className="logout" onClick={() => handleLogout()}>
        LOGOUT
      </div>
    </SidebarContainer>
  );
};

export default Sidebar;
