import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'store';
import { getSsi } from 'store/ssiSlice';
import Overall from './overall';
import Score from './score';
import Engagement from './engagement';
import NPS from './nps';

const SSI = () => {
  const dispatch = useAppDispatch();
  const filter = useAppSelector((state) => state.filter);
  const users = useSelector((state: any) => state.auth.users);
  const data = useSelector((state: any) => state.ssi.data);

  useEffect(() => {
    if (filter.fetchFilterState && users.user_type) {
      dispatch(
        getSsi({
          user_type: users.user_type,
          languge: filter.languageSelected,
          year: filter.yearSelected,
          halfyear: filter.halfYearSelected,
          quarter: filter.quarterSelected,
          month: filter.monthSelected,
          period: filter.periodSelected,
          region: users.region_id ?? '',
          dealer: filter.dealerSelected,
          branch: users.branch_id ?? '',
          buble_ssi_sub: filter?.ssiBubbleS1Selected,
          buble_ssi_cei: filter?.ssiBubbleS2Selected,
          buble_ssi_nps: filter?.ssiBubbleS3Selected,
          buble_ssi_improve: filter?.ssiBubbleS4Selected,
        }),
      );
    }
  }, [filter]);

  return (
    <>
      <Overall data={data?.overview_section ?? {}} />
      <br />
      <Score data={data ?? {}} />
      <br />
      <Engagement data={data ?? {}} />
      <br />
      <NPS data={data ?? {}} />
    </>
  );
};

export default SSI;
