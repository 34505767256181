import { Container } from './style';

import * as Common from 'components/common';
import Indicators from 'components/indicators';
import Mean from 'components/mean';
import CustomBarChart from 'components/customBarChart';
import ColumnChart from 'components/columnChart';
import BarChart from 'components/barChart';
import Legends from 'components/legends';
import CompareTable from 'components/compareTable';
import Note from 'components/note';
import BubbleChart from 'components/bubbleChart';
import { Select } from 'antd';
import { useAppDispatch, useAppSelector } from 'store';
import { bubbleCsiSelected } from 'store/filterSlice';

const After = ({ data }: { data: any }) => {
  const dispatch = useAppDispatch();
  const filter = useAppSelector((state) => state.filter);

  return (
    <Container>
      {data?.sales_performance_csi?.show && (
        <>
          <Common.TitleSection>{data?.sales_performance_csi?.header_text}</Common.TitleSection>
          <Common.BoxContainer>
            <Common.TitleInCard>{data?.sales_performance_csi?.overview?.overview_text}</Common.TitleInCard>
            <div className="grid-2">
              <div>
                <div>
                  <CustomBarChart
                    colors={['#1D79FF', '#00A1A2', '#F06400']}
                    data={data?.sales_performance_csi?.overview?.scores}
                  />
                </div>
                <div>
                  <Legends
                    style={{ marginLeft: '100px' }}
                    legends={data?.sales_performance_csi?.overview?.legend}
                    colors={['#1D79FF', '#00A1A2', '#F06400', '#CCCCCC']}
                  />
                </div>
              </div>
              <div>
                <Indicators
                  headers={['', 'CSI Score', 'CEI Score', 'NPS']}
                  data={[data?.sales_performance_csi?.overview?.target]}
                />
                <br />
                <Mean data={data?.sales_performance_csi?.overview?.definition} />
              </div>
            </div>
            <div className="grid-3">
              <div>
                <Common.TitleInCard>
                  {data?.sales_performance_csi?.csi_score_comparison?.header_text}
                </Common.TitleInCard>
                <Common.SubTitleInCard>
                  {data?.sales_performance_csi?.csi_score_comparison?.maximum_text}
                </Common.SubTitleInCard>
                <div className="column-chart">
                  <ColumnChart
                    data={data?.sales_performance_csi?.csi_score_comparison?.scores}
                    colors={['#CCCCCC', '#1D79FF', '#1D79FF']}
                  />
                </div>
              </div>
              <div>
                <Common.TitleInCard>
                  {data?.sales_performance_csi?.cei_score_comparison?.header_text}
                </Common.TitleInCard>
                <Common.SubTitleInCard>
                  {data?.sales_performance_csi?.cei_score_comparison?.maximum_text}
                </Common.SubTitleInCard>
                <div className="column-chart">
                  <ColumnChart
                    data={data?.sales_performance_csi?.cei_score_comparison?.scores}
                    colors={['#CCCCCC', '#00A1A2', '#00A1A2']}
                  />
                </div>
              </div>
              <div>
                <Common.TitleInCard>{data?.sales_performance_csi?.nps_comparison?.header_text}</Common.TitleInCard>
                <Common.SubTitleInCard>
                  {data?.sales_performance_csi?.nps_comparison?.maximum_text}
                </Common.SubTitleInCard>
                <div className="column-chart">
                  <ColumnChart
                    data={data?.sales_performance_csi?.nps_comparison?.scores}
                    colors={['#CCCCCC', '#F06400', '#F06400']}
                  />
                </div>
              </div>
            </div>
          </Common.BoxContainer>
          <br />
        </>
      )}
      {data?.sales_performance_csi_cusat?.show && (
        <>
          <Common.BoxContainer>
            <Common.TitleInCard>{data?.sales_performance_csi_cusat?.header_text}</Common.TitleInCard>
            <Common.SubTitleInCard>{data?.sales_performance_csi_cusat?.maximum_text}</Common.SubTitleInCard>
            <div className="flex">
              <div
                className="bar-chart"
                style={{ height: `${data?.sales_performance_csi_cusat?.scores?.length * 100}px` }}
              >
                <BarChart
                  data={data?.sales_performance_csi_cusat?.scores}
                  series={[
                    {
                      name: 'nation',
                      data: data?.sales_performance_csi_cusat?.scores?.map?.((score: any) => score.nation),
                    },
                    {
                      name: 'nation_2020',
                      data: data?.sales_performance_csi_cusat?.scores?.map?.((score: any) => score.nation_2020),
                    },
                    {
                      name: 'dealer',
                      data: data?.sales_performance_csi_cusat?.scores?.map?.((score: any) => score.dealer),
                    },
                    {
                      name: 'dealer_2020',
                      data: data?.sales_performance_csi_cusat?.scores?.map?.((score: any) => score.dealer_2020),
                    },
                  ]}
                  colors={['#F06400', '#999999', '#1D79FF', '#CCCCCC']}
                />
              </div>
              <div>
                <Legends
                  style={{ marginTop: '40px' }}
                  vertical
                  legends={data?.sales_performance_csi_cusat?.legend}
                  colors={['#F06400', '#999999', '#1D79FF', '#CCCCCC']}
                />
              </div>
            </div>
            <br />
            <Note
              data={[
                data?.sales_performance_csi_cusat?.base_text,
                data?.sales_performance_csi_cusat?.ref_text,
                data?.sales_performance_csi_cusat?.note_text,
              ]}
            />
          </Common.BoxContainer>
          <br />
        </>
      )}
      {data?.csi_attribute_performance_summary?.show && (
        <>
          <Common.BoxContainer>
            <Common.TitleInCard>{data?.csi_attribute_performance_summary?.header_text}</Common.TitleInCard>
            <br />
            <CompareTable
              strongText={data?.csi_attribute_performance_summary?.strong_text}
              improveText={data?.csi_attribute_performance_summary?.improve_text}
              strongs={data?.csi_attribute_performance_summary?.strong_points}
              improves={data?.csi_attribute_performance_summary?.improve_points}
            />
            <br />
            <Note
              data={[
                data?.csi_attribute_performance_summary?.base_text,
                data?.csi_attribute_performance_summary?.ref_text,
              ]}
            />
          </Common.BoxContainer>
          <br />
        </>
      )}
      {data?.csi_bubble_chart?.show && (
        <>
          <Common.BoxContainer>
            <Common.TitleInCard>{data?.csi_bubble_chart?.header_text}</Common.TitleInCard>
            <br />
            <div className="filter-bubble-group">
              <Select
                value={filter.bubbleCsiSelected}
                onChange={(value) => dispatch(bubbleCsiSelected(value))}
                placeholder="Select"
              >
                {filter?.bubbleCsi?.map?.((data) => {
                  return (
                    <Select.Option key={data.id} value={data.id} disabled={!!+data.id}>
                      {data.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
            <br />
            <div className="flex" style={{ width: '100%' }}>
              {data?.csi_bubble_chart?.bubble?.map?.((chart: any, index: number) => (
                <div key={chart?.color + index} style={{ flex: '1', minWidth: '0' }}>
                  <div style={{ height: '650px', marginBottom: '10px', backgroundColor: chart?.bgColor }}>
                    <BubbleChart data={chart?.data} color={chart?.color} />
                  </div>
                  <div style={{ fontSize: '1.12rem', fontWeight: 500, textAlign: 'center' }}>{chart?.section_text}</div>
                </div>
              ))}
            </div>
          </Common.BoxContainer>
          <br />
        </>
      )}
    </Container>
  );
};

export default After;
