import Chart from 'react-apexcharts';
import { useState, useEffect } from 'react';

type PropsType = {
  colors?: string[];
  series: any[];
  isPercent?: boolean;
};

const DonutChart = ({ colors, series, isPercent }: PropsType) => {
  const [realSeries, setRealSeries] = useState<any>([]);

  const options = {
    chart: {
      fontFamily: 'Prompt, Helvetica, Arial, sans-serif',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: '60%',
        },
      },
    },
    stroke: {
      width: 0,
    },
    legend: { show: false },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '16px',
        fontWeight: 'bold',
      },
      formatter: function (val: any, opts: any) {
        return opts.w.config.series[opts.seriesIndex] + `${isPercent ? '%' : ''}`;
      },
    },
    labels: ['Active', 'Not'],
    colors: series.filter((n) => n).length == 0 ? ['#ccc'] : colors,
    tooltip: {
      enabled: false,
    },
    states: {
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
  };

  useEffect(() => {
    if (series.filter((n) => n).length == 0) {
      setRealSeries([100]);
    } else {
      setRealSeries(series);
    }
  }, [series]);

  return <Chart options={options} series={realSeries} type="donut" width="100%" />;
};

export default DonutChart;
