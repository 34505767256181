import styled from '@emotion/styled/macro';

export const BarChartContainer = styled.div`
  width: 100%;
  height: 100%;

  .wrapper-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .row-in-box {
      padding: 17px 0;
      position: relative;
      flex: 1;
      display: flex;
      align-items: center;
    }

    .label-yaxis {
      width: 100px;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 99px;
        width: 1px;
        height: 100%;
        background-color: #000;
      }
    }

    .wrapper-bar-out {
      flex: 1;

      .bar-out {
        position: relative;
        width: 70%;

        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 1px solid black;
          border-width: 1px 1px 1px 0;
          border-radius: 0 5px 5px 0;
        }

        .target-line {
          position: absolute;
          height: calc(100% + 20px);
          width: 1px;
          border-left: 1px dashed #000;
          border-width: 1px;
          bottom: 0;
          left: 0%;
          z-index: 1;

          .target-name {
            position: absolute;
            left: 5px;
            top: 0;
            white-space: nowrap;
          }
        }

        .bar-out-point {
          position: absolute;
          height: 100%;
          width: 100px;
          display: flex;
          align-items: center;
          right: -110px;
          top: 0;
          z-index: 1;
        }

        .bar-in {
          position: relative;
          height: 22px;
          width: 0%;
          border-radius: 0 5px 5px 0;
          background-color: #ddd;

          .bar-in-point {
            color: #fff;
            position: absolute;
            top: 0;
            left: 5px;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
`;
