import Chart from 'react-apexcharts';

type ColumnChartType = {
  colors?: string[];
  data: { name?: any; score?: any; n?: any; total?: any }[];
  categories?: any[];
  disabledYAxis?: boolean;
  columnWidth?: string;
};

const BasicColumnChart = ({ colors, data, categories, disabledYAxis, columnWidth }: ColumnChartType) => {
  const options = {
    chart: {
      fontFamily: 'Prompt, Helvetica, Arial, sans-serif',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth:
          data?.[0]?.score?.length > 3 ? (columnWidth ? columnWidth : '70%') : columnWidth ? columnWidth : '55%',
        dataLabels: {
          position: 'top', // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: any) {
        if (data?.[0]?.total == 100) {
          return val + ' %';
        }
        return val + ' PTs';
      },
      offsetY: -25,
      style: {
        fontSize: data?.[0]?.score?.length > 5 ? '11px' : '13.5px',
        colors: ['#374151'],
      },
    },
    xaxis: {
      categories: categories || [],
    },
    yaxis: {
      show: !disabledYAxis,
      max: data?.[0]?.total,
      labels: {
        formatter: function (val: any) {
          if (data?.[0]?.total == 100) {
            return val + ' %';
          }
          return val + ' PTs';
        },
      },
    },
    annotations: {
      yaxis: [
        {
          y: 0,
        },
      ],
    },
    legend: {
      show: false,
    },
    colors: colors,
    states: {
      hover: {
        filter: {
          value: 0,
        },
      },
      active: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
  };
  const series = data.map?.((column) => ({ ...column, ...{ data: column?.score } }));

  return <Chart options={options} series={series} type="bar" height="100%" />;
};

export default BasicColumnChart;
