import styled from '@emotion/styled/macro';

export const EngagementContainer = styled.div`
  .flex {
    display: flex;
  }

  .custom-legend {
    .legend {
      align-items: center;
    }
  }
`;
